import { actionConstants } from '../constants';
import { keyBy } from 'lodash';
import { startReqActionType, successReqActionType } from 'helper/requestHelper';

const initialState = {
  allUsers: {},
  nextPageToken: '',
  Developer: {},
  Staff: {},
  groups: [],
  allUserApiKeys: {},
  userSubmissions: {},
  isLoadingUsers: false,
  isLoadingDeveloper: false,
  isLoadingUserApiKeys: false,
  error: null
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case startReqActionType(actionConstants.GET_USERS):
      return {
        ...state,
        isLoadingUsers: true
      };
    case successReqActionType(actionConstants.GET_USERS):
      const newUsersList = keyBy(action.payload.Users || [], 'Username');
      return {
        ...state,
        isLoadingUsers: false,
        allUsers: action.payload.reset
          ? newUsersList
          : {
              ...state.allUsers,
              ...newUsersList
            },
        nextPageToken: action.payload.PaginationToken || null
      };
    case startReqActionType(actionConstants.GET_USER_API_KEYS):
      return {
        ...state,
        isLoadingUserApiKeys: true
      };
    case successReqActionType(actionConstants.GET_USER_API_KEYS):
      return {
        ...state,
        isLoadingUserApiKeys: false,
        allUserApiKeys: keyBy(action.payload, 'username')
      };
    case startReqActionType(actionConstants.GET_USER_SUBMISSIONS_LOGS):
      return {
        ...state,
        isLoadingRequest: true
      };
    case successReqActionType(actionConstants.GET_USER_SUBMISSIONS_LOGS):
      return {
        ...state,
        isLoadingRequest: false,
        userSubmissions: {
          ...state.userSubmissions,
          [action.payload.user_id]: action.payload.data
        }
      };
    case successReqActionType(actionConstants.CREATE_UPDATE_USER):
      return {
        ...state,
        allUsers: {
          [action.payload['Username']]: action.payload
        }
      };
    case successReqActionType(actionConstants.GET_USER_DETAIL):
      return {
        ...state,
        allUsers: {
          [action.payload['Username']]: action.payload
        }
      };
    case successReqActionType(actionConstants.GET_ALL_GROUPS):
      return {
        ...state,
        groups: action.payload
      };
    case successReqActionType(actionConstants.CREATE_GROUP):
      return {
        ...state,
        groups: [...state.groups, action.payload]
      };
    case successReqActionType(actionConstants.UPDATE_GROUP):
      return {
        ...state,
        groups: state.groups.map(gr =>
          gr.GroupName === action.payload.GroupName ? action.payload : gr
        )
      };
    case successReqActionType(actionConstants.DELETE_GROUP):
      return {
        ...state,
        groups: state.groups.filter(gr =>
          gr.groupName !== action.payload
        )
      };
    case successReqActionType(actionConstants.GET_GROUPS_BY_USER):
      return {
        ...state,
        allUsers: {
          ...state.allUsers,
          [action.payload.username]: {
            ...state.allUsers[action.payload.username],
            groups: action.payload.data
          }
        }
      }
    case startReqActionType(actionConstants.GET_USERS_BY_GROUP):
      return {
        ...state
      };
    case successReqActionType(actionConstants.GET_USERS_BY_GROUP):
      return {
        ...state,
        [action.payload.groupName]: keyBy(action.payload.data, 'Username')
      };
    case successReqActionType(actionConstants.ADD_DEVELOPER):
      const newAddingDeveloper = state.allUsers[action.payload.username];
      const newAddingDeveloperList = { ...state.allDevelopers };
      newAddingDeveloperList[newAddingDeveloper.Username] = newAddingDeveloper;
      return {
        ...state,
        allDevelopers: newAddingDeveloperList
      };
    case successReqActionType(actionConstants.DELETE_DEVELOPER):
      const newDeveloper = { ...state.allDevelopers };
      delete newDeveloper[action.payload.username];
      return {
        ...state,
        allDevelopers: newDeveloper
      };
    case successReqActionType(actionConstants.DELETE_USER):
      const newDeleteUsers = { ...state.allUsers };
      delete newDeleteUsers[action.payload.username];
      return {
        ...state,
        allUsers: newDeleteUsers
      };
    case successReqActionType(actionConstants.ENABLE_USER):
      return {
        ...state,
        allUsers: {
          ...state.allUsers,
          [action.payload.username]: {
            ...state.allUsers[action.payload.username],
            Enabled: true
          }
        }
      };
    case successReqActionType(actionConstants.DISABLE_USER):
      return {
        ...state,
        allUsers: {
          ...state.allUsers,
          [action.payload.username]: {
            ...state.allUsers[action.payload.username],
            Enabled: false
          }
        }
      };
    default:
      return state;
  }
};

export default usersReducer;
