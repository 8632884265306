import { actionConstants } from 'redux-layer/constants';
import {
  startReqActionType,
  successReqActionType,
  failReqActionType,
  apiHeader,
  processResponse
} from 'helper/requestHelper';
import { getEnvironment } from 'helper/utils';
import { NotificationManager } from 'react-notifications';
import {
  GENERATE_3D,
  GENERATE_ALL,
  GENERATE_BULLET,
  GENERATE_AI,
  GENERATE_SOURCE,
  GENERATE_VIDEO,
  QUEUE_TYPE_BATCH
} from 'helper/constants';

export const listMediasByUsername = username => async dispatch => {
  dispatch({
    type: startReqActionType(actionConstants.LIST_MEDIA_BY_USERNAME)
  });
  const requestOptions = {
    method: 'GET',
    headers: apiHeader()
  };

  try {
    const response = await fetch(
      `${getEnvironment().API_URL}medias/users/${username}`,
      requestOptions
    );
    const data = await processResponse(response);
    dispatch({
      type: successReqActionType(actionConstants.LIST_MEDIA_BY_USERNAME),
      payload: data
    });
  } catch (error) {
    dispatch({
      type: failReqActionType(actionConstants.LIST_MEDIA_BY_USERNAME)
    });
  }
};

export const getMediasByUsernameByPage =
  (username, start_time, end_time, lastEvaluatedKey = '') =>
  async dispatch => {
    dispatch({
      type: startReqActionType(actionConstants.GET_MEDIAS_BY_USERNAME_BY_PAGE),
      payload: lastEvaluatedKey
    });
    const requestOptions = {
      method: 'GET',
      headers: apiHeader()
    };

    try {
      const response = await fetch(
        `${
          getEnvironment().API_URL
        }medias/users/${username}/by-page?start_time=${start_time}&end_time=${end_time}${
          lastEvaluatedKey
            ? `&last_evaluated_key=${JSON.stringify(lastEvaluatedKey)}`
            : ''
        }`,
        requestOptions
      );
      const data = await processResponse(response);
      dispatch({
        type: successReqActionType(
          actionConstants.GET_MEDIAS_BY_USERNAME_BY_PAGE
        ),
        payload: data
      });
    } catch (error) {
      dispatch({
        type: failReqActionType(actionConstants.GET_MEDIAS_BY_USERNAME_BY_PAGE)
      });
    }
  };

// Customer Statistics
export const listMediasByCustomer =
  (customer_id, start_time, end_time, lastEvaluatedKey = '') =>
  async dispatch => {
    dispatch({
      type: startReqActionType(actionConstants.LIST_MEDIA_BY_CUSTOMER_ID),
      payload: lastEvaluatedKey
    });
    const requestOptions = {
      method: 'GET',
      headers: apiHeader()
    };

    try {
      const response = await fetch(
        `${
          getEnvironment().API_URL
        }medias/customers?start_time=${start_time}&end_time=${end_time}&customer_id=${customer_id}${
          lastEvaluatedKey
            ? `&last_evaluated_key=${JSON.stringify(lastEvaluatedKey)}`
            : ''
        }`,
        requestOptions
      );
      const data = await processResponse(response);
      dispatch({
        type: successReqActionType(actionConstants.LIST_MEDIA_BY_CUSTOMER_ID),
        payload: data
      });
    } catch (error) {
      dispatch({
        type: failReqActionType(actionConstants.LIST_MEDIA_BY_CUSTOMER_ID)
      });
    }
  };

export const paginatedListMedias = (params, reset) => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.PAGINATED_LIST_MEDIAS) });
  const requestOptions = {
    method: 'GET',
    headers: apiHeader()
  };

  try {
    const response = await fetch(
      `${getEnvironment().API_URL}medias?${params}`,
      requestOptions
    );
    const data = await processResponse(response);
    dispatch({
      type: successReqActionType(actionConstants.PAGINATED_LIST_MEDIAS),
      payload: { ...data, reset }
    });
  } catch (error) {
    dispatch({
      type: failReqActionType(actionConstants.PAGINATED_LIST_MEDIAS)
    });
  }
};

// Update media
export const updateMedia = (mediaId, payload) => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.UPDATE_MEDIA) });
  const requestOptions = {
    method: 'PUT',
    headers: apiHeader(),
    body: JSON.stringify(payload)
  };

  try {
    const response = await fetch(
      `${getEnvironment().API_URL}vimmerse/media/${mediaId}`,
      requestOptions
    );
    const data = await processResponse(response);
    dispatch({
      type: successReqActionType(actionConstants.UPDATE_MEDIA),
      payload: data
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.UPDATE_MEDIA) });
  }
};

//Get media detail
export const getMedia =
  (id, detail = false) =>
  async dispatch => {
    dispatch({ type: startReqActionType(actionConstants.GET_MEDIA) });
    const requestOptions = {
      method: 'GET',
      headers: apiHeader()
    };

    try {
      const response = await fetch(
        `${getEnvironment().API_URL}media/${id}`,
        requestOptions
      );
      const data = await processResponse(response);
      dispatch({
        type: successReqActionType(actionConstants.GET_MEDIA),
        payload: data
      });
    } catch (error) {
      dispatch({ type: failReqActionType(actionConstants.GET_MEDIA) });
    }
  };

export const submitMedia =
  (mediaId, generate_type, batch = false) =>
  async dispatch => {
    dispatch({
      type: startReqActionType(actionConstants.SUBMIT_MEDIA)
    });

    const payload = {
      generate_type
    };

    if (batch) {
      payload['queue_type'] = QUEUE_TYPE_BATCH;
    }

    let requestOptions = {
      method: 'POST',
      headers: apiHeader(),
      body: JSON.stringify(payload)
    };

    let url = `${getEnvironment().API_URL}media/${mediaId}/process`;

    if (generate_type === GENERATE_AI) {
      url = `${getEnvironment().API_URL}media/${mediaId}/generateai`;
    }
    try {
      const response = await fetch(url, requestOptions);
      await processResponse(response);
      dispatch(getMedia(mediaId));
      dispatch({
        type: successReqActionType(actionConstants.SUBMIT_MEDIA),
        payload: {}
      });
      switch (generate_type) {
        case GENERATE_ALL:
          NotificationManager.success(
            'Media is requested to generate all',
            'Submitted!'
          );
          break;
        case GENERATE_AI:
          NotificationManager.success(
            'Media is requested to generate ai',
            'Submitted!'
          );
          break;
        case GENERATE_SOURCE:
          NotificationManager.success(
            'Media is requested to generate source',
            'Submitted!'
          );
          break;
        case GENERATE_3D:
          NotificationManager.success(
            'Media is requested to generate 3D and bullet video',
            'Submitted!'
          );
          break;
        case GENERATE_BULLET:
        case GENERATE_VIDEO:
          NotificationManager.success(
            'Media is requested to generate bullet video',
            'Submitted!'
          );
          break;
        default:
          break;
      }
    } catch (error) {
      dispatch({
        type: failReqActionType(actionConstants.SUBMIT_MEDIA),
        error: error.message
      });
      NotificationManager.error(error.message, 'Failed to submit', 8000);
    }
  };

// Submit 3D
export const submitToGenerate3D = mediaId => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.SUBMIT_3D) });

  const requestOptions = {
    method: 'POST',
    headers: apiHeader(),
    body: JSON.stringify({ mediaId })
  };

  const url = `${getEnvironment().API_URL}media/${mediaId}/process/3d`;

  try {
    const response = await fetch(url, requestOptions);
    await processResponse(response);
    dispatch(getMedia(mediaId));
    dispatch({
      type: successReqActionType(actionConstants.SUBMIT_3D),
      payload: {}
    });
    NotificationManager.success('Media is requested to process', 'Submitted!');
  } catch (error) {
    dispatch({
      type: failReqActionType(actionConstants.SUBMIT_3D),
      error: error.message
    });
    NotificationManager.error(error.message, 'Failed to submit 3D', 8000);
  }
};

export const submitToGenerateBullet = mediaId => async dispatch => {
  dispatch({
    type: startReqActionType(actionConstants.SUBMIT_BULLET)
  });

  const requestOptions = {
    method: 'POST',
    headers: apiHeader(),
    body: JSON.stringify({ mediaId })
  };

  const url = `${getEnvironment().API_URL}media/${mediaId}/process/bullet`;

  try {
    const response = await fetch(url, requestOptions);
    const data = await processResponse(response);
    dispatch(getMedia(mediaId));
    dispatch({
      type: successReqActionType(actionConstants.SUBMIT_BULLET),
      payload: data
    });
    NotificationManager.success(
      'Media is requested to generate bullet.',
      'Submitted!'
    );
  } catch (error) {
    dispatch({
      type: failReqActionType(actionConstants.SUBMIT_BULLET),
      error: error.message
    });
    NotificationManager.error(error.message, 'Failed to submit bullet', 8000);
  }
};

export const deleteMedia = mediaId => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.DELETE_MEDIA) });
  const requestOptions = {
    method: 'DELETE',
    headers: apiHeader()
  };

  try {
    const response = await fetch(
      `${getEnvironment().API_URL}media/${mediaId}`,
      requestOptions
    );
    await processResponse(response);
    dispatch({
      type: successReqActionType(actionConstants.DELETE_MEDIA),
      payload: mediaId
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.DELETE_MEDIA) });
  }
};

export const resetErrorMessage = () => dispatch => {
  dispatch({ type: actionConstants.RESET_ERROR_MESSAGE });
};

// Copy new content
export const copyMedia = payload => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.COPY_CONTENT) });

  const requestOptions = {
    method: 'POST',
    headers: apiHeader(),
    body: JSON.stringify(payload)
  };

  const url = `${getEnvironment().API_URL}media/copy`;

  try {
    const data = await fetch(url, requestOptions);
    const retData = await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.COPY_CONTENT),
      payload: retData
    });
    NotificationManager.success(
      'New ID of copied content is ' + retData.id,
      'Succeed to Copy Content',
      3000,
      () => {
        document.location.href = '/media/' + retData.id;
      }
    );
  } catch (error) {
    dispatch({
      type: failReqActionType(actionConstants.COPY_CONTENT),
      error: error.message
    });
  }
};

export const removedUploadedFile = (mediaId, filename) => async dispatch => {
  dispatch({
    type: startReqActionType(actionConstants.REMOVE_UPLOADED_FILE)
  });
  try {
    const requestOptions = {
      method: 'DELETE',
      headers: apiHeader()
    };
    await fetch(
      `${getEnvironment().API_URL}/media/${mediaId}/file/${filename}`,
      requestOptions
    );
    dispatch({
      type: successReqActionType(actionConstants.REMOVE_UPLOADED_FILE),
      payload: { mediaId, filename }
    });
  } catch (error) {
    dispatch({
      type: failReqActionType(actionConstants.REMOVE_UPLOADED_FILE)
    });
  }
};

export async function getUploadedFile(mediaId, filename) {
  const url = `${getEnvironment().API_URL}media/${mediaId}/file/${filename}`;

  const requestOptions = {
    method: 'GET',
    headers: apiHeader()
  };

  fetch(url, requestOptions)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    })
    .catch(console.error);
}

export const setActionRequired = (mediaId, mediaData) => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.SET_ACTION_REQUIRED) });
  try {
    const requestOptions = {
      method: 'POST',
      headers: apiHeader(),
      body: JSON.stringify(mediaData)
    };

    await fetch(
      `${getEnvironment().API_URL}media/${mediaId}/action-required`,
      requestOptions
    );
    dispatch({
      type: successReqActionType(actionConstants.SET_ACTION_REQUIRED),
      payload: mediaData
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.SET_ACTION_REQUIRED) });
  }
};

// Get presets
export const listPreset = username => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.LIST_PRESET) });
  const requestOptions = {
    method: 'GET',
    headers: apiHeader()
  };

  try {
    const response = await fetch(
      `${getEnvironment().API_URL}presets`,
      requestOptions
    );
    const data = await processResponse(response);
    dispatch({
      type: successReqActionType(actionConstants.LIST_PRESET),
      payload: data
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.LIST_PRESET) });
  }
};

export async function downloadUploadedMediaFile(mediaId, filename) {
  const url = `${getEnvironment().API_URL}media/${mediaId}/file/${filename}`;

  const requestOptions = {
    method: 'GET',
    headers: apiHeader()
  };

  fetch(url, requestOptions)
    .then(res => res.blob())
    .then(res => {
      const aElement = document.createElement('a');
      aElement.setAttribute('download', filename);
      const href = URL.createObjectURL(res);
      aElement.href = href;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(href);
    });
}

export const removeUploadedMediaFile =
  (mediaId, filename) => async dispatch => {
    dispatch({
      type: startReqActionType(actionConstants.REMOVE_UPLOADED_MEDIA_FILE)
    });
    try {
      const requestOptions = {
        method: 'DELETE',
        headers: apiHeader()
      };
      await fetch(
        `${getEnvironment().API_URL}media/${mediaId}/file/${filename}`,
        requestOptions
      );
      dispatch({
        type: successReqActionType(actionConstants.REMOVE_UPLOADED_MEDIA_FILE),
        payload: { mediaId, filename }
      });
    } catch (error) {
      dispatch({
        type: failReqActionType(actionConstants.REMOVE_UPLOADED_MEDIA_FILE)
      });
    }
  };

export async function getFileUploadURL(
  mediaId,
  filename,
  content_type,
  upload_to
) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: apiHeader(),
      body: JSON.stringify({ filename, content_type, upload_to })
    };

    const data = await fetch(
      `${getEnvironment().API_URL}media/${mediaId}/file`,
      requestOptions
    );
    return processResponse(data);
  } catch (error) {
    return [];
  }
}
