import { Pagination } from 'react-bootstrap';
import Select from 'react-select';

import './VimmersePaginationBar.scss';

const rowsPerPageOptions = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 40, label: 40 },
  { value: 60, label: 60 }
];

const VimmersePaginationBar = props => {
  const { currentPage } = props;

  const onChangeRowsPerPage = value => {
    if (props.onChangeRowsPerPage) {
      props.onChangeRowsPerPage(value.value);
    }
  };

  const changeCurrentPage = value => {
    if (props.onChangePage) {
      props.onChangePage(value);
    }
  };

  return (
    <div className='vimmerse-pagination-bar'>
      <div className='rows-per-page-bar'>
        <Select
          options={rowsPerPageOptions}
          onChange={onChangeRowsPerPage}
          value={rowsPerPageOptions.find(
            rppo => rppo.value === props.rowsPerPage
          )}
        />
      </div>
      <Pagination>
        <Pagination.Prev
          onClick={() => {
            currentPage > 1 && changeCurrentPage(currentPage - 1);
          }}
          disabled={props.currentPage === 1}
        />
        <Pagination.Item
          active={currentPage === 1}
          onClick={() => changeCurrentPage(1)}
        >
          {1}
        </Pagination.Item>
        {currentPage > 4 && <Pagination.Ellipsis />}
        {currentPage > 3 && (
          <Pagination.Item onClick={() => changeCurrentPage(currentPage - 2)}>
            {currentPage - 2}
          </Pagination.Item>
        )}
        {currentPage > 2 && (
          <Pagination.Item onClick={() => changeCurrentPage(currentPage - 1)}>
            {currentPage - 1}
          </Pagination.Item>
        )}
        {currentPage > 1 && (
          <Pagination.Item active>{currentPage}</Pagination.Item>
        )}
        {!props.isLastPage && <Pagination.Ellipsis disabled />}
        <Pagination.Next
          disabled={props.isLastPage}
          onClick={() => changeCurrentPage(currentPage + 1)}
        />
      </Pagination>
    </div>
  );
};

export { VimmersePaginationBar };
