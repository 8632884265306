import { actionConstants } from 'redux-layer/constants';
import {
  startReqActionType,
  successReqActionType,
  failReqActionType,
  authHeader,
  processResponse
} from 'helper/requestHelper';
import { getEnvironment } from 'helper/utils';

export const getSettings = () => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.GET_SETTINGS) });
  try {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };

    const data = await fetch(
      `${getEnvironment().API_URL}admin/settings`,
      requestOptions
    );
    const retData = await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.GET_SETTINGS),
      payload: retData
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.GET_SETTINGS) });
  }
};

export const updateSetting = (key, payload, callback) => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.UPDATE_SETTING) });
  try {
    const requestOptions = {
      method: 'PUT',
      headers: authHeader(),
      body: JSON.stringify(payload)
    };

    const data = await fetch(
      `${getEnvironment().API_URL}admin/settings/${key}`,
      requestOptions
    );
    const retData = await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.UPDATE_SETTING),
      payload: retData
    });
    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.UPDATE_SETTING) });
  }
};
