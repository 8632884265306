import { actionConstants } from '../constants';
import { keyBy } from 'lodash';
import { startReqActionType, successReqActionType } from 'helper/requestHelper';

const initialState = {
  allCustomers: {},
  isLoadingCustomers: false,
  error: null
};

const customersReducer = (state = initialState, action) => {
  switch (action.type) {
    case startReqActionType(actionConstants.GET_CUSTOMERS):
      return {
        ...state,
        isLoadingCustomers: true
      };
    case successReqActionType(actionConstants.GET_CUSTOMERS):
      return {
        ...state,
        isLoadingCustomers: false,
        allCustomers: keyBy(action.payload, 'id')
      };
    case successReqActionType(actionConstants.ADD_CUSTOMER):
    case successReqActionType(actionConstants.UPDATE_CUSTOMER):
    case successReqActionType(actionConstants.REGENERATE_API_KEY_CUSTOMER):
      return {
        ...state,
        allCustomers: {
          ...state.allCustomers,
          [action.payload.id]: action.payload
        }
      };
    case successReqActionType(actionConstants.REMOVE_CUSTOMER):
      delete state.allCustomers[action.payload.id];
      return {
        ...state,
        allCustomers: {
          ...state.allCustomers
        }
      };
    default:
      return state;
  }
};

export default customersReducer;
