import { actionConstants } from 'redux-layer/constants';
import {
  startReqActionType,
  successReqActionType,
  failReqActionType,
  authHeader,
  processResponse
} from 'helper/requestHelper';
import { getEnvironment } from 'helper/utils';
import { NotificationManager } from 'react-notifications';

export const getVimmerseUsers =
  (
    filterAttribute = '',
    filterValue = '',
    nextPageToken = '',
    reset = 'false'
  ) =>
  async dispatch => {
    dispatch({ type: startReqActionType(actionConstants.GET_USERS) });
    try {
      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      };

      const data = await fetch(
        `${
          getEnvironment().API_URL
        }admin/users?filter_attribute=${filterAttribute}&filter_value=${filterValue}&next_page_token=${encodeURIComponent(nextPageToken)}`,
        requestOptions
      );
      const retData = await processResponse(data);
      dispatch({
        type: successReqActionType(actionConstants.GET_USERS),
        payload: {
          ...retData,
          reset
        }
      });
    } catch (error) {
      dispatch({ type: failReqActionType(actionConstants.GET_USERS) });
    }
  };

// export const getUserSubmissionLogs = user_id => async dispatch => {
//   dispatch({
//     type: startReqActionType(actionConstants.GET_USER_SUBMISSIONS_LOGS)
//   });
//   const requestOptions = {
//     method: 'GET',
//     headers: authHeader()
//   };

//   try {
//     const data = await fetch(
//       `${getEnvironment().API_URL}vimmerse-submission-logs${
//         user_id ? '/users/' + user_id : ''
//       }`,
//       requestOptions
//     );
//     const retData = await processResponse(data);
//     dispatch({
//       type: successReqActionType(actionConstants.GET_USER_SUBMISSIONS_LOGS),
//       payload: {user_id, data: retData}
//     });
//   } catch (error) {
//     dispatch({
//       type: failReqActionType(actionConstants.GET_USER_SUBMISSIONS_LOGS)
//     });
//   }
// };

export const createOrUpdateUser = payload => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.CREATE_UPDATE_USER) });

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload)
  };

  const url = `${getEnvironment().API_URL}admin/users`;

  try {
    const response = await fetch(url, requestOptions);
    const retData = await processResponse(response);
    dispatch({
      type: successReqActionType(actionConstants.CREATE_UPDATE_USER),
      payload: retData
    });
    NotificationManager.success('User information has been updated', 'Success');
  } catch (error) {
    dispatch({
      type: failReqActionType(actionConstants.CREATE_UPDATE_USER),
      error: error.message
    });
    NotificationManager.error(error.message, 'Failed to update user');
  }
};

export const updateUserCredit = payload => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.UPDATE_USER_CREDIT) });

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload)
  };

  const url = `${getEnvironment().API_URL}admin/credits/update`;

  try {
    const response = await fetch(url, requestOptions);
    const retData = await processResponse(response);
    dispatch({
      type: successReqActionType(actionConstants.UPDATE_USER_CREDIT),
      payload: retData
    });
    dispatch(getUserDetail(payload.username));
    NotificationManager.success('Succeed to add new credits', 'Success');
  } catch (error) {
    dispatch({
      type: failReqActionType(actionConstants.UPDATE_USER_CREDIT),
      error: error.message
    });
    NotificationManager.error(error.message, 'Failed');
  }
};

export const updateUserDiscount = payload => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.UPDATE_USER_DISCOUNT) });

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload)
  };

  const url = `${getEnvironment().API_URL}admin/discount/update`;

  try {
    const response = await fetch(url, requestOptions);
    const retData = await processResponse(response);
    dispatch({
      type: successReqActionType(actionConstants.UPDATE_USER_DISCOUNT),
      payload: retData
    });
    dispatch(getUserDetail(payload.username));
    NotificationManager.success('Succeed to add new credits', 'Success');
  } catch (error) {
    dispatch({
      type: failReqActionType(actionConstants.UPDATE_USER_DISCOUNT),
      error: error.message
    });
    NotificationManager.error(error.message, 'Failed');
  }
};

export const getUserCreditHistory = async user_id => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  try {
    const data = await fetch(
      `${getEnvironment().API_URL}/credits/history?reqUser=${user_id}}`,
      requestOptions
    );
    const retData = await processResponse(data);
    return retData;
  } catch (error) {
    return [];
  }
};

export const getUserDetail = username => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.GET_USER_DETAIL) });
  try {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };

    const data = await fetch(
      `${getEnvironment().API_URL}admin/users/${username}`,
      requestOptions
    );
    const retData = await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.GET_USER_DETAIL),
      payload: retData
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.GET_USER_DETAIL) });
  }
};


// Group Related actions
export const getAllGroups = () => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.GET_ALL_GROUPS) });
  try {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };

    const data = await fetch(
      `${getEnvironment().API_URL}admin/groups`,
      requestOptions
    );
    const retData = await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.GET_ALL_GROUPS),
      payload: retData
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.GET_ALL_GROUPS) });
  }
};


export const createGroup = (payload) => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.CREATE_GROUP) });
  try {
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(payload)
    };

    const data = await fetch(
      `${getEnvironment().API_URL}admin/groups`,
      requestOptions
    );
    const retData = await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.CREATE_GROUP),
      payload: retData
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.CREATE_GROUP) });
  }
};


export const updateGroup = (payload) => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.UPDATE_GROUP) });
  try {
    const requestOptions = {
      method: 'PUT',
      headers: authHeader(),
      body: JSON.stringify(payload)
    };

    const data = await fetch(
      `${getEnvironment().API_URL}admin/groups`,
      requestOptions
    );
    const retData = await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.UPDATE_GROUP),
      payload: retData
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.UPDATE_GROUP) });
  }
};


export const deleteGroup = (groupName) => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.DELETE_GROUP) });
  try {
    const requestOptions = {
      method: 'DELETE',
      headers: authHeader()
    };

    const data = await fetch(
      `${getEnvironment().API_URL}admin/groups/${groupName}`,
      requestOptions
    );
    const retData = await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.DELETE_GROUP),
      payload: groupName
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.DELETE_GROUP) });
  }
};


export const getGroupsFromUser = username => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.GET_GROUPS_BY_USER) });
  try {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };

    const data = await fetch(
      `${getEnvironment().API_URL}admin/users/${username}/groups`,
      requestOptions
    );
    const retData = await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.GET_GROUPS_BY_USER),
      payload: { data: retData, username }
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.GET_GROUPS_BY_USER) });
  }
};


export const getUsersByGroup = groupName => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.GET_USERS_BY_GROUP) });
  try {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };

    const data = await fetch(
      `${getEnvironment().API_URL}admin/groups/${groupName}/users`,
      requestOptions
    );
    const retData = await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.GET_USERS_BY_GROUP),
      payload: { data: retData, groupName }
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.GET_USERS_BY_GROUP) });
  }
};

export const addUserToGroup = (username, groupName, cb=null) => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.ADD_DEVELOPER) });

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ username })
  };

  const url = `${getEnvironment().API_URL}admin/groups/${groupName}/users`;

  try {
    const response = await fetch(url, requestOptions);
    await processResponse(response);
    dispatch({
      type: successReqActionType(actionConstants.ADD_DEVELOPER),
      payload: { username, groupName }
    });
    // dispatch(getUsersByGroup(groupName));
    NotificationManager.success(
      `Succeed to add user to ${groupName}`,
      'Success'
    );
    cb && cb()
  } catch (error) {
    dispatch({
      type: failReqActionType(actionConstants.ADD_DEVELOPER),
      error: error.message
    });
    NotificationManager.error(error.message, 'Failed');
  }
};

export const removeUserFromGroup = (username, groupName, cb) => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.DELETE_DEVELOPER) });
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  try {
    const data = await fetch(
      `${getEnvironment().API_URL}admin/groups/${groupName}/users/${username}`,
      requestOptions
    );
    await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.DELETE_DEVELOPER),
      payload: { username, groupName }
    });
    // dispatch(getUsersByGroup(groupName));
    cb && cb();
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.DELETE_DEVELOPER) });
  }
};

export const deleteUser = username => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.DELETE_USER) });
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  try {
    const data = await fetch(
      `${getEnvironment().API_URL}admin/users/${username}`,
      requestOptions
    );
    await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.DELETE_USER),
      payload: { username }
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.DELETE_USER) });
  }
};

export const disableUser = username => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.DISABLE_USER) });
  const requestOptions = {
    method: 'PUT',
    headers: authHeader()
  };

  try {
    const data = await fetch(
      `${getEnvironment().API_URL}admin/users/disable/${username}`,
      requestOptions
    );
    await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.DISABLE_USER),
      payload: { username }
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.DISABLE_USER) });
  }
};

export const enableUser = username => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.ENABLE_USER) });
  const requestOptions = {
    method: 'PUT',
    headers: authHeader()
  };

  try {
    const data = await fetch(
      `${getEnvironment().API_URL}admin/users/enable/${username}`,
      requestOptions
    );
    await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.ENABLE_USER),
      payload: { username }
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.ENABLE_USER) });
  }
};

// export const getUserApiKeys = () => async dispatch => {
//   dispatch({
//     type: startReqActionType(actionConstants.GET_USER_API_KEYS)
//   });
//   const requestOptions = {
//     method: 'GET',
//     headers: authHeader()
//   };

//   try {
//     const data = await fetch(
//       `${getEnvironment().API_URL}admin/getUserApiKeys`,
//       requestOptions
//     );
//     const retData = await processResponse(data);
//     dispatch({
//       type: successReqActionType(actionConstants.GET_USER_API_KEYS),
//       payload: retData
//     });
//   } catch (error) {
//     dispatch({
//       type: failReqActionType(actionConstants.GET_USER_API_KEYS)
//     });
//   }
// };

export const generateApiKey = async username => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  try {
    const data = await fetch(
      `${getEnvironment().API_URL}admin/users/${username}/generateApiKey`,
      requestOptions
    );
    const retData = await processResponse(data);
    NotificationManager.success('Succeed to generate api key', 'Success');
    return retData['api_key'];
  } catch (error) {
    NotificationManager.error(error.message, 'Failed to generate api key');
  }
};
