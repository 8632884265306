/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  FormGroup,
  FormLabel,
  FormControl,
  Button,
  Modal
} from 'react-bootstrap';
import './UserGroupUsers.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addUserToGroup, getUsersByGroup, removeUserFromGroup } from 'redux-layer/actions';
import { convertUTCtoLocal, getAttributeFromUser } from 'helper/utils';
import DataTable from 'react-data-table-component';
import { UsersList } from 'containers/UsersList';

const UserGroupUsers = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSearchUser, setShowSearchUser] = useState(false);
  const { group_name } = useParams();
  const [searchKey, setSearchKey] = useState('');

  const [selectedUser, setSelectedUser] = useState(null);

  const { groupUsers } = useSelector(state => ({
    groupUsers: state.users[group_name] || {}
  }));

  const usersList = Object.values(groupUsers);

  const onReloadUsers = () => {
    dispatch(getUsersByGroup(group_name));
  };

  useEffect(() => {
    if (!usersList.length) {
      onReloadUsers();
    }
  }, []);

  const columns = [
    {
      name: 'Username',
      selector: row => row.Username,
      sortable: true
    },
    {
      name: 'Handle',
      selector: row => {
        const accountName = getAttributeFromUser(row, 'preferred_username');
        return accountName;
      },
      sortable: true
    },
    {
      name: 'Name',
      selector: row => {
        return `${getAttributeFromUser(row, 'given_name')} ${getAttributeFromUser(
          row,
          'family_name'
        )}`;
      },
      width: '200px',
      sortable: true
    },
    {
      name: 'Email',
      selector: row => {
        return getAttributeFromUser(row, 'email');
      },
      sortable: true
    },
    {
      name: 'Email Verified',
      selector: row => {
        return getAttributeFromUser(row, 'email_verified');
      },
      sortable: true
    },
    {
      name: 'Credit',
      selector: row => getAttributeFromUser(row, 'custom:credit'),
      width: '100px',
      sortable: true
    },
    {
      name: 'Created At',
      selector: row =>
        convertUTCtoLocal(row.UserCreateDate).format('YYYY-MM-DD HH:mm:ss'),
      format: (row, index) => convertUTCtoLocal(row.UserCreateDate).format('ll'),
      width: '120px',
      sortable: true
    },
    {
      name: '',
      cell: (row, index, column, id) => (
        <>
          <Link
            to={'/statistics/users/' + row.Username}
            className='btn btn-info text-white me-2'
          >
            Statistics
          </Link>
          <Button onClick={() => onRemoveUserFromGroup(row.Username)}>Remove from {group_name}</Button>
        </>
      )
    }
  ];

  const onUpdateKeyword = event => {
    setSearchKey(event.target.value);
  };

  const rowStyle = [
    {
      when: row => !row.Enabled,
      classNames: ['bg-secondary']
    }
  ];

  let filtered = usersList;

  if (searchKey) {
    filtered = usersList.filter(usr => {
      const givenName = getAttributeFromUser(usr, 'given_name');
      const email = getAttributeFromUser(usr, 'email');

      if (
        (givenName &&
          givenName.toLowerCase().includes(searchKey.toLowerCase())) ||
        (email && email.toLowerCase().includes(searchKey.toLowerCase())) ||
        usr.Username.toLowerCase().includes(searchKey.toLowerCase())
      ) {
        return true;
      }
      return false;
    });
  }

  filtered = filtered.sort((a, b) =>
    a.UserCreateDate > b.UserCreateDate ? -1 : 1
  );

  const onAddUserToGroup = () => {
    dispatch(addUserToGroup(selectedUser.Username, group_name, onReloadUsers));
    setShowSearchUser(false);
  }

  const onRemoveUserFromGroup = (username) => {
    dispatch(removeUserFromGroup(username, group_name, onReloadUsers));
  }

  return (
    <div className='users-list-page'>
      <div className='page-header'>
        <h1>Users from {group_name}</h1>
        <div>
          <Button variant='primary' className='me-1' onClick={onReloadUsers}>
            Reload
          </Button>
          <Button variant='primary' className='me-1' onClick={() => setShowSearchUser(true)}>
            Add to {group_name}
          </Button>
        </div>
      </div>
      <div className='row page-tools'>
        <div className='col-md-3'>
          <FormGroup>
            <FormLabel>Keyword</FormLabel>
            <FormControl
              type='text'
              value={searchKey}
              placeholder='Please input keyword to search'
              onChange={onUpdateKeyword}
            />
          </FormGroup>
        </div>
      </div>
      <div className='contents'>
        <DataTable
          columns={columns}
          data={filtered}
          defaultSortFieldId={9}
          defaultSortAsc={false}
          pagination
          striped
          pointerOnHover
          paginationRowsPerPageOptions={[20, 40, 60]}
          paginationPerPage={40}
          conditionalRowStyles={rowStyle}
          onRowClicked={(row, event) => {
            navigate(`/users/${row.Username}`);
          }}
        />
      </div>
      {showSearchUser && (
        <Modal
          show={true}
          onHide={() => setShowSearchUser(false)}
          backdrop='static'
          size='xl'
        >
          <Modal.Header>Add to {group_name}</Modal.Header>
          <Modal.Body>
            <UsersList onSelectUser={setSelectedUser} searchOnly />
          </Modal.Body>
          <Modal.Footer>
            <Button variant='primary' onClick={onAddUserToGroup}>Add</Button>
            <Button
              variant='secondary'
              onClick={() => setShowSearchUser(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export { UserGroupUsers };
