import { Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { getEnvironment, getMotionTypesFromBatch } from 'helper/utils';
import {
  PROCESSING_STATUS_FAIL,
  PROCESSING_STATUS_PROCESSING,
  PROCESSING_STATUS_SUCCESS
} from 'helper/constants';

const BatchMediaItem = props => {
  const { media, batch } = props;

  const batch_id = batch['id'];

  const { source, media_id } = media;

  if (!media) {
    return <></>;
  }

  const motionTypes = getMotionTypesFromBatch(batch);

  // const sourcePath = `${process.env.REACT_APP_DISTRIBUTION_URL}batch-uploads/${batch_id}/${source}`;

  const { bullet_version } = media;

  return (
    <Card key={media_id} className='mb-5'>
      <Card.Body>
        <div className='row mb-1'>
          <div className='col-md-12'>
            <h4 className='text-uppercase'>{media.item || ''}</h4>
          </div>
          <div className='col-md-4'>
            <div className='detail-item'>
              <h6>Media ({JSON.stringify({source})})</h6>
              {media_id && (
                <p className=''>
                  <NavLink to={'/media/' + media_id} className='link-info'>
                    {media_id}
                  </NavLink>
                </p>
              )}
            </div>
          </div>
          <div className='col-md-3'>
            <div className='detail-item'>
              <h6>Status</h6>
              <p className='text-capitalize'>
                {media?.processing_status || PROCESSING_STATUS_PROCESSING}
              </p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-2'>
            <div className='label'>Source</div>
            {<p>{JSON.stringify(source)}</p>}
            {/* <img src={sourcePath} alt={source} className='w-100' /> */}
          </div>
          {motionTypes.map(mt => {
            const videoURL = `${media.base_url}/batch/${bullet_version}/${mt}/${media_id}.mp4`;
            return (
              <div className='col-md-2' key={mt}>
                <div className='label'>{mt}</div>
                {[PROCESSING_STATUS_SUCCESS, PROCESSING_STATUS_FAIL].includes(
                  media.processing_status
                ) ? (
                  <video controls className='w-100'>
                    <source src={videoURL} type='video/mp4' />
                  </video>
                ) : (
                  <Card>
                    <Card.Body>
                      <h6 className='text-center'>Not Available</h6>
                    </Card.Body>
                  </Card>
                )}
              </div>
            );
          })}
        </div>
      </Card.Body>
    </Card>
  );
};

export { BatchMediaItem };
