import { useState, useEffect } from 'react';
import './SettingDetailPage.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, FormLabel, FormGroup, Button } from 'react-bootstrap';
import { getSettings, updateSetting } from 'redux-layer/actions';

const SettingDetailPage = props => {
  const { setting_key } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { allSettings } = useSelector(state => ({
    allSettings: state.settings.allSettings
  }));

  const [settingKey, setSettingKey] = useState('');
  const [settingValue, setSettingValue] = useState('');
  // const [settingType, setSettingType] = useState('');
  const [settingDescription, setSettingDescription] = useState('');

  const currentSetting = allSettings[setting_key];

  useEffect(() => {
    if (!Object.values(allSettings).length) {
      getSettings();
    }
  }, []);

  useEffect(() => {
    if (currentSetting) {
      setSettingKey(currentSetting.key);
      setSettingValue(currentSetting.value);
      // setSettingType(currentSetting.type);
      setSettingDescription(currentSetting.description);
    }
  }, [currentSetting]);

  const handleSubmit = () => {
    const payload = {
      key: settingKey,
      value: settingValue,
      // type:settingType,
      description: settingDescription
    };

    dispatch(updateSetting(settingKey, payload, () => {
      NotificationManager.success("Your setting has been updated.", "Success", 2000);
    }));
    navigate('/settings');
  };

  return (
    <div className='user-detail-page'>
      <div className='page-header'>
        <h1>{setting_key === 'new' ? 'New Setting' : 'Edit Setting'}</h1>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <FormGroup>
              <FormLabel required>Key</FormLabel>
              <FormControl
                type='text'
                value={settingKey}
                disabled={currentSetting}
                required
                placeholder='Setting Key'
                onChange={e => setSettingKey(e.target.value)}
              />
              <FormLabel>
                <small>
                  You can not change key after the setting is created.
                </small>
              </FormLabel>
            </FormGroup>
          </div>
          <div className='col-md-6'>
            <FormGroup>
              <FormLabel required>Value</FormLabel>
              <FormControl
                type='text'
                value={settingValue}
                required
                placeholder='Value'
                onChange={e => setSettingValue(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className='col-md-12'>
            <FormGroup>
              <FormLabel required>Descripition</FormLabel>
              <FormControl
                type='text'
                value={settingDescription}
                required
                placeholder='Description'
                onChange={e => setSettingDescription(e.target.value)}
              />
            </FormGroup>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <Button onClick={handleSubmit} variant='primary'>
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SettingDetailPage };
