import { actionConstants } from 'redux-layer/constants';
import {
  startReqActionType,
  successReqActionType,
  failReqActionType,
  apiHeader,
  processResponse
} from 'helper/requestHelper';
import { getEnvironment } from 'helper/utils';
import { NotificationManager } from 'react-notifications';
import { BATCH_STATUS_PROCESSING } from 'helper/constants';

export const paginatedListBatches = (params, reset) => async dispatch => {
  dispatch({
    type: startReqActionType(actionConstants.PAGINATED_LIST_BATCHES)
  });
  const requestOptions = {
    method: 'GET',
    headers: apiHeader()
  };

  try {
    const response = await fetch(
      `${getEnvironment().API_URL}batches?${params}`,
      requestOptions
    );
    const data = await processResponse(response);
    dispatch({
      type: successReqActionType(actionConstants.PAGINATED_LIST_BATCHES),
      payload: { ...data, reset }
    });
  } catch (error) {
    dispatch({
      type: failReqActionType(actionConstants.PAGINATED_LIST_BATCHES)
    });
  }
};

export const getBatch = id => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.GET_BATCH) });
  const requestOptions = {
    method: 'GET',
    headers: apiHeader()
  };

  try {
    const response = await fetch(
      `${getEnvironment().API_URL}batch/${id}`,
      requestOptions
    );
    const retData = await processResponse(response);
    dispatch({
      type: successReqActionType(actionConstants.GET_BATCH),
      payload: retData
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.GET_BATCH) });
  }
};

export const updateBatch = (batchId, payload) => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.UPDATE_BATCH) });
  const requestOptions = {
    method: 'PUT',
    headers: apiHeader(),
    body: JSON.stringify(payload)
  };

  try {
    const response = await fetch(
      `${getEnvironment().API_URL}batch/${batchId}`,
      requestOptions
    );
    const data = await processResponse(response);
    dispatch({
      type: successReqActionType(actionConstants.UPDATE_BATCH),
      payload: data
    });
    if (data['status'] === BATCH_STATUS_PROCESSING) {
      NotificationManager.success(
        'All of your contents are in the queue to be processed.',
        'Submitted'
      );
    }
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.UPDATE_BATCH) });
  }
};

export const processBatch =
  (batchId, payload = {}) =>
  async dispatch => {
    dispatch({ type: startReqActionType(actionConstants.PROCESS_BATCH) });

    const requestOptions = {
      method: 'POST',
      headers: apiHeader(),
      body: JSON.stringify(payload)
    };

    const url = `${getEnvironment().API_URL}batch/${batchId}/process`;

    try {
      const response = await fetch(url, requestOptions);
      await processResponse(response);
      dispatch({
        type: successReqActionType(actionConstants.PROCESS_BATCH),
        payload: {}
      });
      NotificationManager.success(
        'All of your contents are in the queue to be processed.',
        'Submitted'
      );
    } catch (error) {
      dispatch({
        type: failReqActionType(actionConstants.PROCESS_BATCH),
        error: error.message
      });
    }
  };

export const deleteBatch = batchId => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.DELETE_BATCH) });
  const requestOptions = {
    method: 'DELETE',
    headers: apiHeader()
  };

  try {
    const response = await fetch(
      `${getEnvironment().API_URL}batch/${batchId}`,
      requestOptions
    );
    await processResponse(response);
    dispatch({
      type: successReqActionType(actionConstants.DELETE_BATCH),
      payload: { batchId }
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.DELETE_BATCH) });
  }
};

export const readyBatch = batchId => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.UPDATE_BATCH) });
  const requestOptions = {
    method: 'GET',
    headers: apiHeader()
  };

  try {
    const response = await fetch(
      `${getEnvironment().API_URL}batch/${batchId}/ready`,
      requestOptions
    );
    const data = await processResponse(response);
    dispatch({
      type: successReqActionType(actionConstants.UPDATE_BATCH),
      payload: data
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.UPDATE_BATCH) });
  }
};

// #############################################

export const grabMediaToBatch = async (batchId, mediaId) => {
  try {
    const requestOptions = {
      method: 'GET',
      headers: apiHeader()
    };
    const response = await fetch(
      `${getEnvironment().API_URL}batch/${batchId}/grab`,
      requestOptions
    );
    const data = await processResponse(response);
    return data;
  } catch (error) {
    return error;
  }
};
