import { useState, useEffect } from 'react';
import './UserGroupDetail.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  FormLabel,
  FormGroup,
  Button,
  Dropdown
} from 'react-bootstrap';
import { createGroup, deleteGroup, updateGroup } from 'redux-layer/actions';

const UserGroupDetail = props => {
  const { group_name } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { groups } = useSelector(state => ({
    groups: state.users.groups
  }));

  const [groupName, setGroupName] = useState('');
  const [description, setDescription] = useState('');

  const currentGroup = groups.find(gr => gr.GroupName === group_name);

  useEffect(() => {
    if (currentGroup) {
      setGroupName(currentGroup.GroupName);
      setDescription(currentGroup.Description);
    }
  }, [currentGroup]);

  const handleSubmit = () => {
    const payload = {
      GroupName: groupName,
      Description: description
    };
    if (currentGroup) {
      dispatch(updateGroup(payload));
    } else {
      dispatch(createGroup(payload));
    }
    navigate('/user-groups');
  };

  const onDeleteGroup = () => {
    if (window.confirm('Do you really want to delete this customer?')) {
      dispatch(deleteGroup(groupName));
    }
    navigate('/user-groups');
  };

  return (
    <div className='user-detail-page'>
      <div className='page-header'>
        <h1>
          {groupName === 'new'
            ? 'New Customer'
            : currentGroup
            ? currentGroup.GroupName
            : ''}
        </h1>
        {/* <div className='actions'>
          {currentGroup && (
            <Dropdown
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <Dropdown.Toggle variant='primary' id='dropdown-play' size='md'>
                Actions
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey='delete' onClick={onDeleteGroup}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div> */}
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-3'>
            <FormGroup>
              <FormLabel required>Group Name</FormLabel>
              <FormControl
                type='text'
                value={groupName}
                disabled={currentGroup}
                required
                placeholder='Group Name'
                onChange={e => setGroupName(e.target.value)}
              />
              <FormLabel>
                <small>
                  You can not change group name after the group is created.
                </small>
              </FormLabel>
            </FormGroup>
          </div>
          <div className='col-md-3'>
            <FormGroup>
              <FormLabel required>Descripition</FormLabel>
              <FormControl
                type='text'
                value={description}
                required
                placeholder='Description'
                onChange={e => setDescription(e.target.value)}
              />
            </FormGroup>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <Button onClick={handleSubmit} variant='primary'>
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { UserGroupDetail };
