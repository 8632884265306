import { keyBy } from 'lodash';
import { actionConstants } from '../constants';
import { startReqActionType, successReqActionType } from 'helper/requestHelper';

const initialState = {
  allSettings: {},
  isLoadingSettings: false,
  error: null
};

const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case startReqActionType(actionConstants.GET_SETTINGS):
      return {
        ...state,
        isLoadingSettings: true
      };
    case successReqActionType(actionConstants.GET_SETTINGS):
      return {
        ...state,
        isLoadingSettings: false,
        allSettings: keyBy(action.payload, 'key')
      };
    case successReqActionType(actionConstants.UPDATE_SETTING):
      return {
        ...state,
        allSettings: {
          ...state.allSettings,
          [action.payload.key]: action.payload
        }
      };
    default:
      return state;
  }
};

export default settingReducer;
