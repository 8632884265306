import { useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import './StatusBar.scss';

const StatusBar = props => {
  const {
    isLoadingPreset,
    isLoadingMediaList,
    isLoadingUsers,
    isLoadingBatch
  } = useSelector(state => ({
    isLoadingMediaList: state.media.isLoadingMediaList,
    isLoadingUsers: state.users.isLoadingUsers,
    isLoadingBatch: state.batch.isLoadingBatch
  }));

  const getShowingTexts = () => {
    const messages = [];
    if (isLoadingPreset) {
      messages.push('Loading presets....');
    }
    if (isLoadingMediaList) {
      messages.push('Loading medias....');
    }
    if (isLoadingUsers) {
      messages.push('Loading users....');
    }
    if (isLoadingBatch) {
      messages.push('Loading batches....');
    }
    return messages;
  };

  const showingTexts = getShowingTexts();

  if (showingTexts.length < 1) {
    return '';
  }

  return (
    <Alert className='global-status-bar' variant='info'>
      <ul>
        {showingTexts.map(st => (
          <li key={st}>{st}</li>
        ))}
      </ul>
    </Alert>
  );
};

export { StatusBar };
