import { useState } from 'react';
import {
  Button,
  Modal,
  FormGroup,
  FormLabel,
  FormControl
} from 'react-bootstrap';
import './ActionRequiredDialog.scss';
import { PROCESSING_STATUS_ACTION_REQUIRED } from 'helper/constants';

const ActionRequiredDialog = props => {
  const { onSubmit, onClose, error_message = '' } = props;
  const [errorMessage, setErrorMessage] = useState(error_message);

  const submitModal = () => {
    const payload = {
      error_message: errorMessage,
      processing_status: PROCESSING_STATUS_ACTION_REQUIRED
    };
    onSubmit(payload);
  };

  return (
    <Modal show={true} onHide={onClose} backdrop='static' size='md'>
      <Modal.Header>Action Required</Modal.Header>
      <Modal.Body>
        <FormGroup className='mb-3'>
          <FormLabel>Reason</FormLabel>
          <FormControl
            as='textarea'
            rows={3}
            value={errorMessage}
            placeholder='Please input reason of action required...'
            onChange={e => setErrorMessage(e.target.value)}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='danger' onClick={submitModal}>
          Confirm
        </Button>
        <Button variant='secondary' onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { ActionRequiredDialog };
