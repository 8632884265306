import './DetailViewItem.scss';

const DetailViewItem = props => {
  return (
    <div className='detail-item'>
      <h6>{props.title}</h6>
      <p>{props.value}</p>
    </div>
  );
};

export { DetailViewItem };
