/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import {
  getEc2Status,
  getQueueSizes,
  getTools,
  purgeQueue
} from 'redux-layer/actions';
import { Button } from 'react-bootstrap';
import { QueueSizeRender } from './QueueSizeRender';
import { NotificationManager } from 'react-notifications';

const SQSRender = props => {
  const dispatch = useDispatch();

  const { isLoadingTools, isLoadingQueueSizes, isLoadingEC2Status } =
    useSelector(state => ({
      isLoadingTools: state.tools.isLoadingTools,
      isLoadingQueueSizes: state.queue.isLoadingQueueSizes,
      isLoadingEC2Status: state.ec2.isLoadingEC2Status
    }));

  const onRefresh = () => {
    if (!isLoadingTools) {
      dispatch(getTools());
    }

    if (!isLoadingQueueSizes) {
      dispatch(getQueueSizes());
    }

    if (!isLoadingEC2Status) {
      dispatch(getEc2Status());
    }
  };

  const onPurgeQueue = type => {
    if (window.confirm('Are you sure to purge messages from the queue?')) {
      dispatch(
        purgeQueue(type, () => {
          NotificationManager.success('Purged successfully.', 'Queue Purged');
          onRefresh();
        })
      );
    }
  };

  return (
    <div className='sqs-render'>
      <div className='row'>
        <div className='col-sm-4'>
          <div className='queue-size-render-box'>
            <QueueSizeRender
              title="Process Queue"
              type='process_queue'
              detail='approximate_number_of_messages'
            />
            <Button
              variant='danger'
              size='sm'
              onClick={() => onPurgeQueue('process_queue')}
            >
              Purge
            </Button>
          </div>
        </div>
        <div className='col-sm-4'>
          <div className='queue-size-render-box'>
            <QueueSizeRender
              title="Batch Queue"
              type='batch_queue'
              detail='approximate_number_of_messages'
            />
            <Button
              variant='danger'
              size='sm'
              onClick={() => onPurgeQueue('batch_queue')}
            >
              Purge
            </Button>
          </div>
        </div>
        <div className='col-sm-4'>
          <div className='queue-size-render-box'>
            <QueueSizeRender
              title='Generate AI Queue'
              type='generate_ai_queue'
              detail='approximate_number_of_messages'
            />
            <Button
              variant='danger'
              size='sm'
              onClick={() => onPurgeQueue('generate_ai_queue')}
            >
              Purge
            </Button>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export { SQSRender };
