/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { FormGroup, FormLabel, FormControl, Table } from 'react-bootstrap';
import './UsersList.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getVimmerseUsers } from 'redux-layer/actions';
import Select from 'react-select';
import { VimmersePaginationBar } from 'components/VimmersePaginationBar';
import { UserItem } from './UserItem';

const userAttributeOptions = [
  { value: 'username', label: 'Username' },
  { value: 'email', label: 'Email' },
  { value: 'phone_number', label: 'Phone' },
  { value: 'given_name', label: 'Given Name' },
  { value: 'family_name', label: 'Family Name' },
  { value: 'preferred_username', label: 'Handle' },
  { value: 'sub', label: 'Sub' }
];

const UsersList = props => {
  const dispatch = useDispatch();

  const { searchOnly } = props;

  const [selectedUser, setSelectedUser] = useState(null);

  const [filterAttribute, setFilterAttribute] = useState(
    userAttributeOptions[0]
  );
  const [filterValue, setFilterValue] = useState('');

  const [perPage, setPerPage] = useState(searchOnly ? 10 : 40);
  const [page, setPage] = useState(1);

  const { allUsers, nextPageToken } = useSelector(state => ({
    allUsers: state.users.allUsers,
    nextPageToken: state.users.nextPageToken
  }));  

  // useEffect(() => {
  //   onLoadGroupUsers();
  // }, []);

  // const onLoadGroupUsers = () => {
  //   dispatch(getUsersByGroup('Developer'));
  //   dispatch(getUsersByGroup('Staff'));
  // };

  useEffect(() => {
    triggerSearch(filterAttribute, filterValue);
    setPage(1);
  }, [filterValue, filterAttribute]);

  const triggerSearch = useCallback(
    debounce((filterAttribute, filterValue) => {
      searchVimmerseUser(filterAttribute, filterValue, '', true);
    }, 600),
    []
  );

  const onSelectUser = (user) => {
    setSelectedUser(user);
    props.onSelectUser && props.onSelectUser(user);
  }

  const searchVimmerseUser = (
    filterAttribute,
    filterValue,
    nextPageToken,
    reset
  ) => {
    dispatch(
      getVimmerseUsers(filterAttribute.value, filterValue, nextPageToken, reset)
    );
  };

  const usersList = Object.values(allUsers);

  // Pagination Related

  const onChangePage = page => {
    const required_count = page * perPage;
    if (required_count > usersList.length) {
      if (nextPageToken) {
        searchVimmerseUser(filterAttribute, filterValue, nextPageToken, false);
      } else {
        return;
      }
    }

    setPage(page);
  };

  const onChangeRowsPerPage = newPerPage => {
    setPerPage(newPerPage);
    setPage(1);
  };

  const displayingUsers = usersList.slice((page - 1) * perPage, page * perPage);

  return (
    <div className='users-list-page'>
      <div className='page-header'>
        <h1>Search Users</h1>
        {!searchOnly && (
          <div>
            <Link to={'/users/new'} className='btn btn-primary'>
              New User
            </Link>
          </div>
        )}
      </div>
      <div className='row page-tools'>
        <div className='col-md-2'>
          <FormGroup>
            <FormLabel>Search By</FormLabel>
            <Select
              value={filterAttribute}
              options={userAttributeOptions}
              onChange={setFilterAttribute}
            />
          </FormGroup>
        </div>
        <div className='col-md-3'>
          <FormGroup>
            <FormLabel>Search For</FormLabel>
            <FormControl
              type='text'
              value={filterValue}
              placeholder='Search users by attribute'
              onChange={e => setFilterValue(e.target.value)}
            />
          </FormGroup>
        </div>
      </div>
      <div className='contents'>
        <VimmersePaginationBar
          onChangePage={onChangePage}
          currentPage={page}
          isLastPage={!nextPageToken}
          onChangeRowsPerPage={onChangeRowsPerPage}
          rowsPerPage={perPage}
        />
        <Table striped responsive>
          <thead>
            <td>Username</td>
            <td>Handle</td>
            <td>Name</td>
            <td>Email</td>
            <td>Email Verified</td>
            <td>Credit</td>
            <td>Created At</td>
            {/* <td>Group</td> */}
            {!searchOnly && <td>Action</td>}
          </thead>
          <tbody>
            {displayingUsers.map(user => (
              <UserItem
                user={user}
                selectedUser={selectedUser}
                onSelectUser={onSelectUser}
                searchOnly={searchOnly}
              />
            ))}
          </tbody>
        </Table>

        <VimmersePaginationBar
          onChangePage={onChangePage}
          currentPage={page}
          isLastPage={!nextPageToken}
          onChangeRowsPerPage={onChangeRowsPerPage}
          rowsPerPage={perPage}
        />
      </div>
    </div>
  );
};

export { UsersList };
