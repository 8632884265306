/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import {
  Badge,
  Button,
  ButtonGroup,
  FormControl,
  FormGroup,
  FormLabel,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  actOnEC2,
  actOnLambda,
  clearQueueFromTool,
  getLambdas,
  requestRestartTool
} from 'redux-layer/actions';
import { JENKINS_ADDRESSES, VCV_SERVER_MAP } from 'helper/constants';

const ToolsRender = props => {
  const { allTools, allLambdas, ec2ToolsStatus } = useSelector(state => ({
    allTools: state.tools.allTools,
    allLambdas: state.tools.allLambdas,
    ec2ToolsStatus: state.ec2.ec2ToolsStatus
  }));

  const [priorityThreshold, setPriorityThreshold] = useState('9');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLambdas());
  }, []);

  const onRestartLambdaTool = (id, ip, action = 'reboot') => {
    const payload = {
      id,
      ip,
      action
    };
    dispatch(actOnLambda(payload));
  };

  const onRestartEc2Tool = (id, ip, action = 'reboot') => {
    const payload = {
      id,
      ip,
      action,
      tool: 'tool'
    };
    dispatch(actOnEC2(payload));
  };

  const onClearQueue = async payload => {
    await clearQueueFromTool(payload);
  };

  const onRequestRestart = async payload => {
    await requestRestartTool(payload);
  };

  const navigate = useNavigate();

  const toolsList = Object.values(allTools);

  toolsList.forEach(tool => {
    const assigningLambda = allLambdas.find(lambda =>
      tool.server_address.includes(lambda.ip)
    );
    if (assigningLambda) {
      tool.name = assigningLambda.name;
      tool.ip = assigningLambda.ip;
      tool.instance_id = assigningLambda.id;
      tool.instance_type = assigningLambda.instance_type;
    }

    const assigningEC2 = ec2ToolsStatus.find(ec2 =>
      tool.server_address.includes(ec2.ip)
    );
    if (assigningEC2) {
      tool.name = 'EC2 - ' + assigningEC2.name;
      tool.ip = assigningEC2.ip;
      tool.instance_id = assigningEC2.id;
      tool.instance_type = assigningEC2.instance_type;
    }
  });

  const getVCVNameFromIP = addr => {
    const vcv = VCV_SERVER_MAP.find(vcv => addr.includes(vcv.ip));
    if (vcv) {
      return vcv.name;
    }
    return '';
  };

  const columns = [
    {
      name: 'Name',
      width: '200px',
      selector: row =>
        row.name || row.server_name || getVCVNameFromIP(row.server_address),
      sortable: true
    },
    {
      name: 'Requested At',
      width: '130px',
      selector: row =>
        moment.unix(row.request_timestamp).format('MM-DD HH:mm:ss'),
      sortable: true
    },
    {
      name: 'Address',
      width: '200px',
      selector: row => row.server_address,
      sortable: true
    },
    {
      name: 'Server Type',
      width: '160px',
      selector: row => row.server_type,
      sortable: true,
      cell: row => {
        return (
          <div className={row.server_type.replaceAll('_', ' ')}>
            <div className='background-bar'></div>
            {row.server_type}
          </div>
        );
      }
    },
    {
      name: 'Queue Size',
      width: '100px',
      selector: row => row.weighted_queue_size
    },
    {
      name: 'Queue Status',
      width: '220px',
      cell: row => {
        if (row.status) {
          const { detail } = row.status;
          if (detail) {
            return <Badge className='bg-success'>OK</Badge>;
          }
          const { queued_tasks, running_tasks, failed_tasks } = row.status;
          return (
            <>
              <OverlayTrigger
                placement='top'
                delay={{ show: 250, hide: 3000 }}
                overlay={
                  <Tooltip>
                    {Object.values(queued_tasks).map(task_info => {
                      const { media_id, task_name, priority } = task_info;
                      if (priority < priorityThreshold) {
                        return (
                          <p key={media_id} className='text-warning'>
                            {media_id} ({task_name})
                          </p>
                        );
                      } else {
                        return (
                          <p key={media_id}>
                            {media_id} ({task_name})
                          </p>
                        );
                      }
                    })}
                  </Tooltip>
                }
              >
                <Badge className='bg-warning me-1'>
                  QUEUE: {Object.values(queued_tasks).length || 0}
                </Badge>
              </OverlayTrigger>

              <OverlayTrigger
                placement='top'
                delay={{ show: 250, hide: 3000 }}
                overlay={
                  <Tooltip>
                    {Object.values(running_tasks).map(task_info => {
                      const { media_id, task_name, priority } = task_info;
                      if (priority < priorityThreshold) {
                        return (
                          <p key={media_id} className='text-warning'>
                            {media_id} ({task_name})
                          </p>
                        );
                      } else {
                        return (
                          <p key={media_id}>
                            {media_id} ({task_name})
                          </p>
                        );
                      }
                    })}
                  </Tooltip>
                }
              >
                <Badge className='bg-success me-1'>
                  RUNNING: {Object.values(running_tasks).length || 0}
                </Badge>
              </OverlayTrigger>

              <OverlayTrigger
                placement='top'
                delay={{ show: 250, hide: 3000 }}
                overlay={
                  <Tooltip>
                    {Object.values(failed_tasks).map(task_info => {
                      const { media_id, task_name, priority } = task_info;
                      if (priority < priorityThreshold) {
                        return (
                          <p key={media_id} className='text-warning'>
                            {media_id} ({task_name})
                          </p>
                        );
                      } else {
                        return (
                          <p key={media_id}>
                            {media_id} ({task_name})
                          </p>
                        );
                      }
                    })}
                  </Tooltip>
                }
              >
                <Badge className='bg-danger me-1'>
                  FAILED: {Object.values(failed_tasks).length || 0}
                </Badge>
              </OverlayTrigger>
            </>
          );
        }
        return <Badge className='bg-danger'>FAILED</Badge>;
      }
    },
    {
      name: 'CPU Usage',
      width: '120px',
      selector: row => (row.status ? row.status.cpu_percentage + '%' : '0%'),
      sortable: true
    },
    {
      name: 'RAM Usage',
      width: '120px',
      selector: row =>
        row.status && row.status.total_memory
          ? ((row.status.used_memory / row.status.total_memory) * 100).toFixed(
              2
            ) + '%'
          : '0%',
      sortable: true
    },
    {
      name: 'V-RAM',
      width: '120px',
      selector: row =>
        row.status && row.status.total_gpu
          ? (
              ((row.status.total_gpu - row.status.available_gpu) /
                row.status.total_gpu) *
              100
            ).toFixed(2) + '%'
          : '0%',
      sortable: true
    },
    {
      name: 'Action',
      cell: row => {
        return (
          <ButtonGroup>
            <Button
              variant='info'
              size='sm'
              className='me-2 text-light'
              onClick={() => {
                window.open(row.server_address + '/queue-status', '_blank');
              }}
            >
              Status
            </Button>
            <Button
              variant='info'
              size='sm'
              className='me-2 text-light'
              onClick={() => {
                window.open(row.server_address + '/download-logs', '_blank');
              }}
            >
              Logs
            </Button>
            <Button
              variant='warning'
              size='sm'
              className='me-2 text-light'
              onClick={() => {
                const payload = {
                  address: row.server_address,
                  priority: Number(priorityThreshold)
                };
                onClearQueue(payload);
              }}
            >
              Clear LP
            </Button>
            <Button
              variant='danger'
              size='sm'
              className='me-2'
              onClick={() => {
                const payload = {
                  address: row.server_address
                };
                onClearQueue(payload);
              }}
            >
              Clear
            </Button>
            <Button
              variant='warning'
              size='sm'
              className='me-2 text-white'
              onClick={() => {
                const payload = {
                  address: row.server_address
                };
                onRequestRestart(payload);
              }}
            >
              Request Restart
            </Button>
            {row.ip && (
              <Button
                variant='danger'
                size='sm'
                onClick={() => {
                  if (row.name.includes('EC2 - ')) {
                    onRestartEc2Tool(row.instance_id, row.ip, 'reboot');
                  } else {
                    onRestartLambdaTool(row.instance_id, row.ip, 'reboot');
                  }
                }}
              >
                Restart
              </Button>
            )}
          </ButtonGroup>
        );
      }
    },
    {
      name: 'Jenkins',
      selector: row => row.server_address,
      cell: row => {
        const pattern = /(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})/g;
        let match, ip;
        while ((match = pattern.exec(row.server_address)) !== null) {
          ip = match[0];
        }
        if (!ip) {
          return '';
        }
        const jenkins_obj = JENKINS_ADDRESSES.find(jenk => jenk.ip === ip);
        if (!jenkins_obj) {
          return '';
        }
        return (
          <a target='__blank' href={jenkins_obj.jenkins} className='text-info'>
            {jenkins_obj.jenkins}
          </a>
        );
      }
    }
  ];

  return (
    <div className='tools-render'>
      <FormGroup>
        <FormLabel>Priority Threshold</FormLabel>
        <FormControl
          value={priorityThreshold}
          className='w-25 mb-1'
          onChange={e => setPriorityThreshold(e.target.value)}
        />
      </FormGroup>
      <DataTable
        columns={columns}
        data={toolsList}
        defaultSortFieldId={1}
        pagination
        striped
        pointerOnHover
        paginationRowsPerPageOptions={[20, 40, 60]}
        paginationPerPage={20}
        onRowClicked={(row, event) => {
          if (props.EnableRowClick) {
            navigate(`/tools/${row.id}`);
          }
        }}
      />
    </div>
  );
};

export { ToolsRender };
