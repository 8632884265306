import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Modal,
  FormGroup,
  FormLabel,
  FormControl,
  FormText
} from 'react-bootstrap';
import Select from 'react-select';
import './AdvancedEditDialog.scss';
import {
  CAPTURE_OPTIONS,
  CAPTURE_TYPE_3DVIDEO_VIMMERSE,
  MOTION_TYPE_OPTIONS,
  DEFAULT_POSE_PRESET,
  DEFAULT_POSE_PRESET_PARAMS,
  ANIMATEAI_QUANTITY,
  DEFAULT_GENERATE_AI
} from 'helper/constants';

const rotationOptions = [
  { value: '-1', label: 'Automatic' },
  { value: '0', label: 'Normal' },
  { value: '90', label: '90°' },
  { value: '180', label: '180°' },
  { value: '270', label: '270°' }
];

const scaleOptions = [
  { value: '4.0', label: '400%' },
  { value: '3.0', label: '300%' },
  { value: '2.0', label: '200%' },
  { value: '1.0', label: 'Normal' },
  { value: '0.75', label: '75%' },
  { value: '0.5', label: '50%' },
  { value: '0.25', label: '25%' }
];

const maskSourceOptions = [
  { value: 'Auto', label: 'Auto' },
  { value: 'No', label: 'No' },
  { value: 'Yes', label: 'Yes' },
  { value: 'Copy', label: 'Copy' }
];

const depthProcessingOptions = [
  { value: 'Auto', label: 'Auto' },
  { value: 'EstimateRelative', label: 'EstimateRelative' },
  { value: 'EstimateMetric', label: 'EstimateMetric' }
];

const backgroundProcessingOptions = [
  { value: 'Auto', label: 'Auto' },
  { value: 'AdvancedInpaintStill', label: 'AdvancedInpaintStill' },
  { value: 'InpaintStill', label: 'InpaintStill' },
  { value: 'InpaintMoving', label: 'InpaintMoving' },
  { value: 'None', label: 'None' }
];

const maskProcessingOptions = [
  { value: 'Auto', label: 'Auto' },
  { value: 'Method1', label: 'Method1' },
  { value: 'Method2', label: 'Method2' },
  { value: 'Method3', label: 'Method3' }
];

const textProcessingOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' }
];

const erosionRadiusOptions = [
  { value: '1', label: '1' },
  { value: '3', label: '3' },
  { value: '5', label: '5' },
  { value: '9', label: '9' },
  { value: '12', label: '12' },
  { value: '15', label: '15' },
  { value: '18', label: '18' },
  { value: '21', label: '21' },
  { value: '27', label: '27' },
  { value: '33', label: '33' }
];

const stabilizeMotionOptions = [
  { value: 'Auto', label: 'Auto' },
  { value: 'No', label: 'No' },
  { value: 'Yes', label: 'Yes' }
];

const randomSeedOptions = [
  { value: 'No', label: 'No' },
  { value: 'Yes', label: 'Yes' }
];

const nsfwFilterTypeOptions = [
  { value: 'Auto', label: 'Auto' },
  { value: 'Unsafe', label: 'Unsafe' },
  { value: 'Safe', label: 'Safe' }
];

const pipelinePresetOptions = [
  { value: 'Normal', label: 'Normal' },
  { value: 'Fast', label: 'Fast' },
  { value: 'Slow', label: 'Slow' }
];

const virtualViewsOptions = [
  { value: '-1', label: 'Auto' },
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '3', label: '3' },
  { value: '5', label: '5' }
];

const rendererTypeOptions = [
  { value: 'vimmersePlayer', label: 'VimmersePlayer' },
  { value: 'vimmerseMPI', label: 'VimmerseMPI' }
];

const AdvancedEditDialog = props => {
  const { media, onSubmit, onClose } = props;
  const [captureFormat, setCaptureFormat] = useState(CAPTURE_OPTIONS[0]);
  const [rotation, setRotation] = useState(rotationOptions[1]);
  const [scaleFactor, setScaleFactor] = useState(scaleOptions[3]);
  const [maskSource, setMaskSource] = useState(maskSourceOptions[0]);
  const [errorReason, setErrorReason] = useState('');
  const [depthProcessing, setDepthProcessing] = useState(
    depthProcessingOptions[0]
  );
  const [backgroundProcessing, setBackgroundProcessing] = useState(
    backgroundProcessingOptions[0]
  );
  const [maskProcessing, setMaskProcessing] = useState(
    maskProcessingOptions[0]
  );
  const [textProcessing, setTextProcessing] = useState(
    textProcessingOptions[0]
  );
  const [erosionRadius, setErosionRadius] = useState(erosionRadiusOptions[2]);
  const [stabilizeMotion, setStabilizeMotion] = useState(
    stabilizeMotionOptions[1]
  );
  const [randomSeed, setRandomSeed] = useState(randomSeedOptions[1]);
  const [nsfwFilterType, setNsfwFilterType] = useState(
    nsfwFilterTypeOptions[0]
  );
  const [pipelinePreset, setPipelinePreset] = useState(
    pipelinePresetOptions[0]
  );
  const [virtualViews, setVirtualViews] = useState(virtualViewsOptions[0]);
  const [virtualMotionAmount, setVirtualMotionAmount] = useState('4');
  const [rendererType, setRendererType] = useState(rendererTypeOptions[0]);

  const [backgroundPreset, setBackgroundPreset] = useState(null);
  const [audioPreset, setAudioPreset] = useState(null);

  const { audioPresets, backgroundPresets } = useSelector(state => ({
    audioPresets: state.media.presets.audios || [],
    backgroundPresets: state.media.presets.backgrounds || []
  }));

  const audioPresetOptions = audioPresets.map(ap => ({
    value: ap,
    label: ap.split(',')[0],
    audio: `${process.env.REACT_APP_DISTRIBUTION_URL}presets/audio/${ap}`
  }));

  const backgroundPresetOptions = backgroundPresets.map(bp => ({
    value: bp,
    label: bp.split(',')[0],
    image: `${process.env.REACT_APP_DISTRIBUTION_URL}presets/background/Thumbnails/${bp}`
  }));

  const [posePreset, setPosePreset] = useState(DEFAULT_POSE_PRESET);
  const [generateAi, setGenerateAi] = useState(
    JSON.stringify(DEFAULT_GENERATE_AI)
  );

  useEffect(() => {
    if (media) {
      const { submit_params, pose_preset, generate_ai } = media;

      setCaptureFormat(
        CAPTURE_OPTIONS.find(co => co.value === media.capture_type)
      );
      setErrorReason(media.error_message || '');
      try {
        setScaleFactor(
          scaleOptions.find(
            so => so.value === submit_params.downscale_factor || so.value === submit_params.scale_factor
          ) || scaleOptions[3]
        );

        setRotation(
          rotationOptions.find(ro => ro.value === submit_params.rotation) ||
            rotationOptions[1]
        );

        setMaskSource(
          maskSourceOptions.find(
            co => co.value === submit_params.mask_source
          ) || maskSourceOptions[0]
        );

        setDepthProcessing(
          depthProcessingOptions.find(
            dpo => dpo.value === submit_params.depth_processing
          ) || depthProcessingOptions[0]
        );

        setBackgroundProcessing(
          backgroundProcessingOptions.find(
            bpo => bpo.value === submit_params.background_processing
          ) || backgroundProcessingOptions[0]
        );

        setMaskProcessing(
          maskProcessingOptions.find(
            mpo => mpo.value === submit_params.mask_processing
          ) || maskProcessingOptions[0]
        );

        setTextProcessing(
          textProcessingOptions.find(
            tpo => tpo.value === submit_params.text_processing
          ) || textProcessingOptions[0]
        );

        setErosionRadius(
          erosionRadiusOptions.find(
            ero => ero.value === submit_params.erosion_radius
          ) || erosionRadiusOptions[2]
        );

        setStabilizeMotion(
          stabilizeMotionOptions.find(
            smo => smo.value === submit_params.stabilize_motion
          ) || stabilizeMotionOptions[1]
        );

        setRandomSeed(
          randomSeedOptions.find(
            rso => rso.value === submit_params.random_seed
          ) || randomSeedOptions[1]
        );

        setNsfwFilterType(
          nsfwFilterTypeOptions.find(
            nfto => nfto.value === submit_params.nsfw_filter_type
          ) || nsfwFilterTypeOptions[0]
        );

        setPipelinePreset(
          pipelinePresetOptions.find(
            ppo => ppo.value === submit_params.pipeline_preset
          ) || pipelinePresetOptions[0]
        );

        setVirtualViews(
          virtualViewsOptions.find(
            vvo => vvo.value === submit_params.virtual_views
          ) || virtualViewsOptions[0]
        );

        setVirtualMotionAmount(submit_params.virtual_motion_amount || 4);

        setRendererType(
          rendererTypeOptions.find(
            rto => rto.value === submit_params.renderer_type
          ) || rendererTypeOptions[0]
        );

        setAudioPreset(
          audioPresetOptions.find(
            apo => apo.value === submit_params.audio_preset
          )
        );

        setBackgroundPreset(
          backgroundPresetOptions.find(
            bpo => bpo.value === submit_params.background_preset
          )
        );

        if (pose_preset) {
          setPosePreset(JSON.stringify(pose_preset, undefined, 2));
        }

        if (generate_ai) {
          setGenerateAi(JSON.stringify(generate_ai, undefined, 2));
        }
      } catch (error) {}
    }
  }, [media]);

  const submitModal = () => {
    if (!isValidJSON(posePreset)) {
      alert('Pose Preset value is not valid JSON format.');
      return;
    }
    if (!isValidJSON(generateAi)) {
      alert('Generate AI value is not valid JSON format.');
      return;
    }

    const payload = {
      capture_type: captureFormat.value,
      scale_factor: scaleFactor.value,
      mask_source: maskSource.value,
      rotation: rotation.value,
      erosion_radius: erosionRadius.value,
      depth_processing: depthProcessing.value,
      background_processing: backgroundProcessing.value,
      mask_processing: maskProcessing.value,
      text_processing: textProcessing.value,
      stabilize_motion: stabilizeMotion.value,
      virtual_views: virtualViews.value,
      virtual_motion_amount: virtualMotionAmount,
      renderer_type: rendererType.value,
      random_seed: randomSeed.value,
      nsfw_filter_type: nsfwFilterType.value,
      pipeline_preset: pipelinePreset.value,
      error_message: errorReason,
      pose_preset: JSON.parse(posePreset),
      generate_ai: JSON.parse(generateAi)
    };

    if (audioPreset) {
      payload['audio_preset'] = audioPreset.value;
    } else {
      payload['audio_preset'] = 'null';
    }

    if (backgroundPreset) {
      payload['background_preset'] = backgroundPreset.value;
    } else {
      payload['background_preset'] = 'null';
    }

    onSubmit(payload);
  };

  const isValidJSON = val => {
    try {
      JSON.parse(val);
    } catch (error) {
      return false;
    }
    return true;
  };

  const loadDefaultPosePreset = () => {
    setPosePreset(JSON.stringify(DEFAULT_POSE_PRESET, undefined, 2));
  };

  const loadDEFAULT_GENERATE_AI = () => {
    setGenerateAi(JSON.stringify(DEFAULT_GENERATE_AI, undefined, 2));
  };

  const loadDefaultListPosePreset = () => {
    const listPosePreset = [];
    MOTION_TYPE_OPTIONS.forEach(mto => {
      const poseText = { ...DEFAULT_POSE_PRESET };
      switch (mto.value) {
        case 'Auto':
        case 'Text2Video':
        case 'Control':
        case 'StableVideoDiffusion':
        case 'LeonardoMotion':
        case 'HaiperVideo':
        case 'DynamiCrafter':
        case 'DynamiCrafterInterp':
        case 'Prompt':
          poseText['MotionType'] = mto.value;
          poseText['Params'] =
            DEFAULT_POSE_PRESET_PARAMS +
            '|Quantity=' +
            ANIMATEAI_QUANTITY.toString();
          if (mto.value == 'Prompt') {
            poseText['Params'] =
              'Prompt=|PromptStrength=1|' + poseText['Params'];
          } else if (mto.value == 'Text2Video') {
            poseText['Params'] = 'Prompt=|' + poseText['Params'];
          }
          break;
        default:
          poseText['MotionType'] = 'Parallax';
          poseText['Params'] =
            'CameraPath=' +
            mto.value +
            '|' +
            DEFAULT_POSE_PRESET_PARAMS +
            '|Quantity=1';
      }
      listPosePreset.push(poseText);
    });
    setPosePreset(JSON.stringify(listPosePreset, undefined, 2));
  };

  return (
    <Modal show={true} onHide={onClose} backdrop='static' size='xl'>
      <Modal.Header>Edit</Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-md-6'>
            <h4>3D Settings</h4>
            <FormGroup className='mb-3'>
              <FormLabel>Capture format</FormLabel>
              <Select
                options={CAPTURE_OPTIONS}
                variant='danger'
                value={captureFormat}
                onChange={val => setCaptureFormat(val)}
              />
            </FormGroup>
            <FormGroup className='mb-3'>
              <FormLabel>Rotation</FormLabel>
              <Select
                options={rotationOptions}
                variant='danger'
                value={rotation}
                onChange={val => setRotation(val)}
              />
            </FormGroup>
            <FormGroup className='mb-3'>
              <FormLabel>Scale factor</FormLabel>
              <Select
                isDisabled={
                  captureFormat &&
                  captureFormat.value === CAPTURE_TYPE_3DVIDEO_VIMMERSE
                }
                options={scaleOptions}
                variant='danger'
                value={scaleFactor}
                onChange={val => setScaleFactor(val)}
              />
            </FormGroup>
            <FormGroup className='mb-3'>
              <FormLabel>Depth fill radius</FormLabel>
              <Select
                options={erosionRadiusOptions}
                value={erosionRadius}
                onChange={setErosionRadius}
              />
            </FormGroup>
            <FormGroup className='mb-3'>
              <FormLabel>Stabilize motion?</FormLabel>
              <Select
                options={stabilizeMotionOptions}
                value={stabilizeMotion}
                onChange={setStabilizeMotion}
              />
            </FormGroup>
            <FormGroup className='mb-3'>
              <FormLabel>Random seed?</FormLabel>
              <Select
                options={randomSeedOptions}
                value={randomSeed}
                onChange={setRandomSeed}
              />
            </FormGroup>
            <FormGroup className='mb-3'>
              <FormLabel>NSFW filter type</FormLabel>
              <Select
                options={nsfwFilterTypeOptions}
                value={nsfwFilterType}
                onChange={setNsfwFilterType}
              />
            </FormGroup>
            <FormGroup className='mb-3'>
              <FormLabel>Pipeline preset</FormLabel>
              <Select
                options={pipelinePresetOptions}
                value={pipelinePreset}
                onChange={setPipelinePreset}
              />
            </FormGroup>
            <FormGroup className='mb-3'>
              <FormLabel>Mask source?</FormLabel>
              <Select
                options={maskSourceOptions}
                variant='danger'
                value={maskSource}
                onChange={setMaskSource}
              />
            </FormGroup>
            <FormGroup className='mb-3'>
              <FormLabel>Depth processing</FormLabel>
              <Select
                options={depthProcessingOptions}
                value={depthProcessing}
                onChange={setDepthProcessing}
              />
            </FormGroup>
            <FormGroup className='mb-3'>
              <FormLabel>Background processing</FormLabel>
              <Select
                options={backgroundProcessingOptions}
                value={backgroundProcessing}
                onChange={setBackgroundProcessing}
              />
            </FormGroup>
            <FormGroup className='mb-3'>
              <FormLabel>Mask processing</FormLabel>
              <Select
                options={maskProcessingOptions}
                value={maskProcessing}
                onChange={setMaskProcessing}
              />
            </FormGroup>
            <FormGroup className='mb-3'>
              <FormLabel>Text processing</FormLabel>
              <Select
                options={textProcessingOptions}
                value={textProcessing}
                onChange={setTextProcessing}
              />
            </FormGroup>
            <FormGroup className='mb-3'>
              <FormLabel>Virtual views</FormLabel>
              <Select
                options={virtualViewsOptions}
                value={virtualViews}
                onChange={setVirtualViews}
              />
            </FormGroup>
            <FormGroup className='mb-3'>
              <FormLabel>Virtual motion amount</FormLabel>
              <FormControl
                value={virtualMotionAmount}
                placeholder='From 0 to 8'
                onChange={e => setVirtualMotionAmount(e.target.value)}
              />
            </FormGroup>
            <FormGroup className='mb-3'>
              <FormLabel>Renderer type</FormLabel>
              <Select
                options={rendererTypeOptions}
                value={rendererType}
                onChange={setRendererType}
              />
            </FormGroup>
            <FormGroup className='mb-3'>
              <FormLabel>Background Preset</FormLabel>
              <Select
                options={backgroundPresetOptions}
                value={backgroundPreset}
                onChange={setBackgroundPreset}
                formatOptionLabel={op => (
                  <div className='preset-option'>
                    <img src={op.image} alt='bg-image' width={50} />
                    <span>{op.label}</span>
                  </div>
                )}
              />
              <Button
                variant='secondary'
                size='sm'
                className='mt-2'
                onClick={() => {
                  setBackgroundPreset(null);
                }}
              >
                Reset
              </Button>
            </FormGroup>

            <FormGroup className='mb-3'>
              <FormLabel>Audio Preset</FormLabel>
              <Select
                options={audioPresetOptions}
                value={audioPreset}
                onChange={setAudioPreset}
              />
              <div className='d-flex'>
                {audioPreset && (
                  <audio controls>
                    <source src={audioPreset.audio} type='audio/mp3' />
                  </audio>
                )}
                <Button
                  variant='secondary'
                  size='sm'
                  className='mt-2'
                  onClick={() => {
                    setAudioPreset(null);
                  }}
                >
                  Reset
                </Button>
              </div>
            </FormGroup>
          </div>
          <div className='col-md-6'>
            <h4>Pose Preset</h4>
            <FormGroup className='mb-3'>
              <FormLabel>JSON Editor</FormLabel>
              <FormControl
                as='textarea'
                rows={10}
                value={posePreset}
                onChange={e => setPosePreset(e.target.value)}
              />
              <FormText className='text-muted'>
                Please input valid pose preset object or array of pose preset.
              </FormText>
              <div className='mt-2'>
                {isValidJSON(posePreset) ? (
                  <span className='text-success'>Json is Valid</span>
                ) : (
                  <span className='text-danger'>Json is not Valid</span>
                )}
              </div>
              <div className='d-flex mt-2'>
                <Button
                  size='sm'
                  variant='primary'
                  onClick={loadDefaultPosePreset}
                >
                  Load Default
                </Button>
                <Button
                  size='sm'
                  variant='primary'
                  className='ms-2'
                  onClick={loadDefaultListPosePreset}
                >
                  Load Default List Pose Preset
                </Button>
              </div>
            </FormGroup>
            <h4>Generate AI</h4>
            <FormGroup className='mb-3'>
              <FormLabel>JSON Editor</FormLabel>
              <FormControl
                as='textarea'
                rows={10}
                value={generateAi}
                onChange={e => setGenerateAi(e.target.value)}
              />
              <FormText className='text-muted'>
                Please input valid generate ai object.
              </FormText>
              <div className='mt-2'>
                {isValidJSON(generateAi) ? (
                  <span className='text-success'>Json is Valid</span>
                ) : (
                  <span className='text-danger'>Json is not Valid</span>
                )}
              </div>
              <div className='d-flex mt-2'>
                <Button
                  size='sm'
                  variant='primary'
                  onClick={loadDEFAULT_GENERATE_AI}
                >
                  Load Default
                </Button>
              </div>
            </FormGroup>
            <h4>General Settings</h4>
            <FormGroup className='mb-3'>
              <FormLabel>Error reason</FormLabel>
              <FormControl
                as='textarea'
                rows={3}
                value={errorReason}
                placeholder='Please input reason of the error here...'
                onChange={e => setErrorReason(e.target.value)}
              />
            </FormGroup>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={submitModal}>
          Save
        </Button>
        <Button variant='secondary' onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { AdvancedEditDialog };
