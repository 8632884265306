import { actionConstants } from 'redux-layer/constants';
import {
  startReqActionType,
  successReqActionType,
  failReqActionType,
  authHeader,
  processResponse
} from 'helper/requestHelper';
import { getEnvironment } from 'helper/utils';

export const getQueueSizes = () => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.GET_QUEUE_SIZES) });
  try {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };

    const data = await fetch(
      `${getEnvironment().API_URL}admin/queues/sizes`,
      requestOptions
    );
    const retData = await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.GET_QUEUE_SIZES),
      payload: retData
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.GET_QUEUE_SIZES) });
  }
};

export const purgeQueue = (queue_type, callback) => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.PURGE_QUEUE) });
  console.log(queue_type);
  try {
    const requestOptions = {
      method: 'PUT',
      headers: authHeader(),
      body: JSON.stringify({ queue_type })
    };

    console.log(requestOptions);

    const data = await fetch(
      `${getEnvironment().API_URL}admin/queues/purge`,
      requestOptions
    );
    const retData = await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.PURGE_QUEUE),
      payload: retData
    });
    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.PURGE_QUEUE) });
  }
};
