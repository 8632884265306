/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import {
  Badge,
  Button,
  ButtonGroup,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import {
  actOnEC2,
  requestClearBatch,
  requestRestartPM2
} from 'redux-layer/actions';

const EC2Render = props => {
  const { ec2Status } = useSelector(state => ({
    ec2Status: state.ec2.ec2Status
  }));

  const dispatch = useDispatch();

  const columns = [
    {
      name: 'ID',
      selector: row => row.id
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true
    },
    {
      name: 'IP',
      width: '160px',
      selector: row => row.ip,
      sortable: true
    },
    {
      name: 'CPU Usage',
      width: '160px',
      selector: row => (row.status ? row.status.cpu_percent + '%' : '0%'),
      sortable: true
    },
    {
      name: 'RAM Usage',
      width: '160px',
      selector: row => (row.status ? row.status.percent + '%' : '0%'),
      sortable: true
    },
    {
      name: 'V-RAM',
      width: '120px',
      selector: row =>
        row.status && row.status.total_gpu
          ? (
              ((row.status.total_gpu - row.status.available_gpu) /
                row.status.total_gpu) *
              100
            ).toFixed(2) + '%'
          : '0%',
      sortable: true
    },
    {
      name: 'Instance Type',
      width: '150px',
      selector: row => row.instance_type,
      sortable: true
    },
    {
      name: 'Status',
      width: '100px',
      selector: row => row.state['Name']
    },
    {
      name: 'Running Medias',
      cell: (row, index, column, id) => (
        <OverlayTrigger
          placement='top'
          delay={{ show: 250, hide: 3000 }}
          overlay={
            <Tooltip>
              {row.running_medias && Array.isArray(row.running_medias)
                ? row.running_medias.map(media_info => {
                    const {
                      cmdline,
                      cpu_affinity,
                      cpu_percent,
                      memory_info,
                      memory_percent
                    } = media_info;
                    const media_index = cmdline.indexOf('-m') + 1;
                    const media_id = cmdline[media_index];

                    const customer_index = cmdline.indexOf('-c') + 1;
                    const customer_id = cmdline[customer_index];

                    const batch_index = cmdline.indexOf('-b');

                    return (
                      <p
                        key={media_id}
                        className={`${batch_index > -1 ? 'text-warning' : ''}`}
                      >
                        {media_id} ({memory_percent.toFixed(2)}%) {customer_id}
                      </p>
                    );
                  })
                : ''}
            </Tooltip>
          }
        >
          <Badge className='bg-success'>
            {row.running_medias ? row.running_medias.length : 0}
          </Badge>
        </OverlayTrigger>
      )
    },
    {
      name: 'Action',
      cell: row => (
        <ButtonGroup className='ec2-button-group' size='sm'>
          {row.state['Name'] === 'stopped' && (
            <Button
              variant='primary'
              onClick={() => onAction(row.id, row.ip, 'start')}
            >
              Start
            </Button>
          )}
          {row.state['Name'] === 'running' && (
            <Button
              variant='info'
              className='text-light'
              onClick={() => onAction(row.id, row.ip, 'reboot')}
            >
              Reboot
            </Button>
          )}
          {row.state['Name'] === 'running' && (
            <Button
              variant='warning'
              className='text-light'
              onClick={() => onRestartPM2(row.ip)}
            >
              Restart PM2
            </Button>
          )}
          {row.state['Name'] === 'running' && (
            <Button
              variant='warning'
              className='text-light'
              onClick={() => onClearBatch(row.ip)}
            >
              Clear Batch
            </Button>
          )}
          {row.state['Name'] === 'running' && (
            <Button
              variant='danger'
              onClick={() => onAction(row.id, row.ip, 'stop')}
            >
              Stop
            </Button>
          )}
        </ButtonGroup>
      )
    }
  ];

  const onAction = (id, ip, action) => {
    const payload = {
      id,
      ip,
      action
    };
    dispatch(actOnEC2(payload));
  };

  const onClearBatch = ip => {
    const payload = {
      address: `http://${ip}`
    };
    requestClearBatch(payload);
  };

  const onRestartPM2 = ip => {
    const payload = {
      address: `http://${ip}`
    };
    requestRestartPM2(payload);
  };

  return (
    <DataTable
      columns={columns}
      data={ec2Status}
      defaultSortFieldId={2}
      pagination
      striped
      pointerOnHover
      paginationRowsPerPageOptions={[20, 40, 60]}
      paginationPerPage={20}
    />
  );
};

export { EC2Render };
