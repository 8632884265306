import React from 'react';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store from './redux-layer/store';
import App from './App';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import './index.scss';

Amplify.configure(config);
const target = document.querySelector('#root');

render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>,
  target
);
