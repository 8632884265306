const ColorLabel = props => {
    const { label, value, color } = props

    return(
        <div className={`color-label ${color}`}>
            <div className="label">{label}</div>
            <div className="value">{value}</div>
        </div>
    )

}

export { ColorLabel }