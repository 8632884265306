import { useState } from 'react';
import {
  Button,
  Modal,
  FormGroup,
  FormLabel,
  FormControl,
  FormText
} from 'react-bootstrap';
import Select from 'react-select';
import './GeneralEditDialog.scss';
import { PUBLIC_STATUS_OPTIONS } from 'helper/constants';

const GeneralEditDialog = props => {
  const { media, onSubmit, onClose } = props;
  const [title, setTitle] = useState(media.title || '');
  const [sortWeight, setSortWeight] = useState(
    (media.sort_weight || 0).toString()
  );
  const [description, setDescription] = useState(media.description || '');
  const [publicStatus, setPublicStatus] = useState(
    PUBLIC_STATUS_OPTIONS.find(pso => pso.value === media.visibility_status) ||
      PUBLIC_STATUS_OPTIONS[0]
  );

  const submitModal = () => {
    const payload = {
      title,
      description,
      visibility_status: publicStatus.value,
      sort_weight: Number(sortWeight).toString()
    };
    onSubmit(payload);
  };

  return (
    <Modal show={true} onHide={onClose} backdrop='static' size='md'>
      <Modal.Header>{media ? media.title : 'New media'}</Modal.Header>
      <Modal.Body>
        <FormGroup className='mb-3'>
          <FormLabel>Title</FormLabel>
          <FormControl
            type='text'
            value={title}
            placeholder='Please input title here...'
            onChange={e => setTitle(e.target.value)}
          />
        </FormGroup>
        <FormGroup className='mb-3'>
          <FormLabel>Description</FormLabel>
          <FormControl
            as='textarea'
            rows={3}
            value={description}
            placeholder='Please input descriptions here...'
            onChange={e => setDescription(e.target.value)}
          />
        </FormGroup>
        <FormGroup className='mb-3'>
          <FormLabel>Visibility</FormLabel>
          <Select
            options={PUBLIC_STATUS_OPTIONS}
            value={publicStatus}
            onChange={setPublicStatus}
          />
        </FormGroup>
        <FormGroup className='mb-3'>
          <FormLabel>Sort Weight</FormLabel>
          <FormControl
            type='text'
            value={sortWeight}
            placeholder='Sort weight'
            onChange={e => setSortWeight(e.target.value)}
          />
          <FormText>The bigger weight has the higher priority.</FormText>
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={submitModal}>
          Save
        </Button>
        <Button variant='secondary' onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { GeneralEditDialog };
