import { actionConstants } from '../constants';
import { keyBy } from 'lodash';
import { failReqActionType, startReqActionType, successReqActionType } from 'helper/requestHelper';

const initialState = {
  allTools: {},
  allLambdas: [],

  isLoadingTools: false,
  isLoadingLambdas: false,
  error: null
};

const toolsReducer = (state = initialState, action) => {
  switch (action.type) {
    case startReqActionType(actionConstants.GET_TOOLS):
      return {
        ...state,
        isLoadingTools: true
      };
    case successReqActionType(actionConstants.GET_TOOLS):
      return {
        ...state,
        isLoadingTools: false,
        allTools: keyBy(action.payload, 'id')
      };
    case successReqActionType(actionConstants.ADD_TOOL):
    case successReqActionType(actionConstants.UPDATE_TOOL):
      return {
        ...state,
        allTools: {
          ...state.allTools,
          [action.payload.id]: action.payload
        }
      };
    case successReqActionType(actionConstants.REMOVE_TOOL):
      delete state.allTools[action.payload.id];
      return {
        ...state,
        allTools: {
          ...state.allTools
        }
      };
    case startReqActionType(actionConstants.GET_LAMBDAS):
      return {
        ...state,
        isLoadingLambdas: true
      };
    case successReqActionType(actionConstants.GET_LAMBDAS):
      return {
        ...state,
        isLoadingLambdas: false,
        allLambdas: action.payload
      };
    case failReqActionType(actionConstants.GET_LAMBDAS):
      return {
        ...state,
        isLoadingLambdas: false,
        allLambdas: []
      };
    default:
      return state;
  }
};

export default toolsReducer;
