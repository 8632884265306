import { actionConstants } from '../constants';
import { unset } from 'lodash';
import {
  successReqActionType,
  failReqActionType,
  startReqActionType
} from 'helper/requestHelper';
import { PROCESSING_STATUS_ACTION_REQUIRED } from 'helper/constants';

const initialState = {
  error: null,
  submitError: '',

  presets: {},
  isLoadingPreset: false,

  allMedia: {},

  userMedias: [],

  paginatedMedias: [],
  lastEvaluatedKeys: null,
  totalCount: 0,

  isLoadingMediaList: false,

  isLoadingUserMedias: false,
  paginatedUserMedias: [],
  paginatedUserMediasLastEvaluatedKeys: '',

  customerMedias: [],
  isLoadingCustomerMedia: false,
  customerMediasLastEvaluatedKeys: ''
};

const mediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case startReqActionType(actionConstants.LIST_MEDIA_BY_USERNAME):
      return {
        ...state,
        isLoadingMediaList: true
      };
    case successReqActionType(actionConstants.LIST_MEDIA_BY_USERNAME):
      return {
        ...state,
        isLoadingMediaList: false,
        userMedias: action.payload
      };

    case startReqActionType(actionConstants.GET_MEDIAS_BY_USERNAME_BY_PAGE):
      return {
        ...state,
        isLoadingUserMedias: true,
        paginatedUserMedias: action.payload ? state.paginatedUserMedias : []
      };
    case successReqActionType(actionConstants.GET_MEDIAS_BY_USERNAME_BY_PAGE):
      return {
        ...state,
        isLoadingUserMedias: action.payload.last_evaluated_key ? true : false,
        paginatedUserMediasLastEvaluatedKeys: action.payload.last_evaluated_key,
        paginatedUserMedias: [
          ...state.paginatedUserMedias,
          ...action.payload.data
        ]
      };

    case startReqActionType(actionConstants.LIST_MEDIA_BY_CUSTOMER_ID):
      return {
        ...state,
        isLoadingCustomerMedia: true,
        customerMedias: action.payload ? state.customerMedias : []
      };
    case successReqActionType(actionConstants.LIST_MEDIA_BY_CUSTOMER_ID):
      return {
        ...state,
        isLoadingCustomerMedia: action.payload.last_evaluated_key
          ? true
          : false,
        customerMediasLastEvaluatedKeys: action.payload.last_evaluated_key,
        customerMedias: [...state.customerMedias, ...action.payload.data]
      };

    case startReqActionType(actionConstants.PAGINATED_LIST_MEDIAS):
      return {
        ...state,
        isLoadingMediaList: true
      };

    case successReqActionType(actionConstants.PAGINATED_LIST_MEDIAS):
      return {
        ...state,
        isLoadingMediaList: false,
        paginatedMedias: action.payload.reset
          ? action.payload.data
          : [...state.paginatedMedias, ...action.payload.data],
        lastEvaluatedKeys: action.payload.last_evaluated_key,
        totalCount: action.payload.reset
          ? action.payload.total_count
          : state.totalCount
      };
    case failReqActionType(actionConstants.PAGINATED_LIST_MEDIAS):
      return {
        ...state,
        isLoadingMediaList: false
      };

    case successReqActionType(actionConstants.UPDATE_MEDIA):
      return {
        ...state,
        allMedia: {
          ...state.allMedia,
          [action.payload.id]: {
            ...state.allMedia[action.payload.id],
            ...action.payload
          }
        }
      };
    case successReqActionType(actionConstants.GET_MEDIA):
      return {
        ...state,
        allMedia: {
          ...state.allMedia,
          [action.payload.id]: action.payload
        }
      };

    case successReqActionType(actionConstants.DELETE_MEDIA):
      const newContents = { ...state.allMedia };
      unset(newContents, action.payload);
      return {
        ...state,
        allMedia: newContents
      };
    // case successReqActionType(actionConstants.CREATE_CONTENT):
    //   return {
    //     ...state
    //   };
    // case failReqActionType(actionConstants.CREATE_CONTENT):
    //   return {
    //     ...state,
    //     submitError: action.error
    //   };
    case actionConstants.RESET_ERROR_MESSAGE:
      return {
        ...state,
        submitError: ''
      };
    case startReqActionType(actionConstants.LIST_PRESET):
      return {
        ...state,
        isLoadingPreset: true
      };
    case successReqActionType(actionConstants.LIST_PRESET):
      return {
        ...state,
        isLoadingPreset: false,
        presets: action.payload
      };
    case successReqActionType(actionConstants.SET_ACTION_REQUIRED):
      return {
        ...state,
        isLoadingRequest: false,
        allMedia: {
          ...state.allMedia,
          [action.payload.id]: {
            ...state.allMedia[action.payload.id],
            ...action.payload,
            processing_status: PROCESSING_STATUS_ACTION_REQUIRED
          }
        }
      };
    default:
      return state;
  }
};

export default mediaReducer;
