/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './UserGroupsList.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getAllGroups } from 'redux-layer/actions';
import DataTable from 'react-data-table-component';

const columns = [
  {
    name: 'Name',
    selector: row => row.GroupName,
    sortable: true
  },
  {
    name: 'Description',
    selector: row => row.Description
  },
  {
    name: '',
    cell: (row, index, column, id) => (
      <>
        <Link
          to={'/user-groups/' + row.GroupName}
          className='btn btn-primary text-white me-2'
        >
          Edit
        </Link>
        <Link
          to={'/user-groups/users/' + row.GroupName}
          className='btn btn-info text-white'
        >
          Users
        </Link>
      </>
    )
  }
];

const UserGroupsList = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { groups } = useSelector(state => ({
    groups: state.users.groups
  }));

  useEffect(() => {
    if (!groups.length) {
      dispatch(getAllGroups());
    }
  }, []);

  return (
    <div className='user-groups-list-page'>
      <div className='page-header'>
        <h1>User Groups</h1>
        <div>
          <Link to={'/user-groups/new'} className='btn btn-primary'>
            New Group
          </Link>
        </div>
      </div>
      <div className='contents'>
        <DataTable
          columns={columns}
          data={groups}
          defaultSortFieldId={9}
          defaultSortAsc={false}
          pagination
          striped
          pointerOnHover
          paginationRowsPerPageOptions={[20, 40, 60]}
          paginationPerPage={20}
          onRowClicked={(row, event) => {
            navigate(`/user-groups/users/${row.GroupName}`);
          }}
        />
      </div>
    </div>
  );
};

export { UserGroupsList };
