/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import {
  Badge,
  Button,
  ButtonGroup,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { actOnEC2 } from 'redux-layer/actions';

const APIServerRender = props => {
  const { apiServers } = useSelector(state => ({
    apiServers: state.ec2.apiServers
  }));

  const dispatch = useDispatch();

  const columns = [
    {
      name: 'ID',
      selector: row => row.id
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true
    },
    {
      name: 'IP',
      width: '160px',
      selector: row => row.ip,
      sortable: true
    },
    {
      name: 'CPU Usage',
      width: '160px',
      selector: row => row.status ? row.status.cpu_percent + '%' : '0%',
      sortable: true
    },
    {
      name: 'RAM Usage',
      width: '160px',
      selector: row => row.status ? row.status.percent + '%' : '0%',
      sortable: true
    },
    {
      name: 'Instance Type',
      width: '150px',
      selector: row => row.instance_type,
      sortable: true
    },
    {
      name: 'Status',
      width: '100px',
      selector: row => row.state['Name']
    },
    {
      name: 'Action',
      cell: row => (
        <ButtonGroup className='ec2-button-group' size='sm'>
          {row.state['Name'] === 'stopped' && (
            <Button
              variant='primary'
              onClick={() => onAction(row.id, row.ip, 'start')}
            >
              Start
            </Button>
          )}
          {row.state['Name'] === 'running' && (
            <Button
              variant='info'
              className='text-light'
              onClick={() => onAction(row.id, row.ip, 'reboot')}
            >
              Reboot
            </Button>
          )}
          {row.state['Name'] === 'running' && (
            <Button
              variant='danger'
              onClick={() => onAction(row.id, row.ip, 'stop')}
            >
              Stop
            </Button>
          )}
        </ButtonGroup>
      )
    }
  ];

  const onAction = (id, ip, action) => {
    const payload = {
      id,
      ip,
      action
    };
    dispatch(actOnEC2(payload));
  };

  return (
    <DataTable
      columns={columns}
      data={apiServers}
      defaultSortFieldId={2}
      pagination
      striped
      pointerOnHover
      paginationRowsPerPageOptions={[20, 40, 60]}
      paginationPerPage={20}
    />
  );
};

export { APIServerRender };
