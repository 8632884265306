/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';

const CustomerSelect = props => {
  const { allCustomers } = useSelector(state => ({
    allCustomers: state.customers.allCustomers
  }));

  useEffect(() => {
    const customerString = localStorage.getItem('customer');
    if (customerString) {
      const customerValue = JSON.parse(customerString);
      if (props.onChangeCustomer) {
        props.onChangeCustomer(customerValue);
      }
    }
  }, []);

  const customersOptions = Object.values(allCustomers).map(co => ({
    value: co.id,
    label: co.name,
    customer_id: co.customer_id,
    customer_key: co.key || ''
  }));

  const onChangeCustomer = value => {
    localStorage.setItem('customer', JSON.stringify(value));
    if (props.onChangeCustomer) {
      props.onChangeCustomer(value);
    }
  };

  return (
    <Select
      className='env-selector mb-1'
      classNamePrefix='env-select'
      onChange={onChangeCustomer}
      options={customersOptions}
      value={props.customer}
    />
  );
};

export { CustomerSelect };
