import { actionConstants } from 'redux-layer/constants';
import {
  startReqActionType,
  successReqActionType,
  failReqActionType,
  authHeader,
  processResponse
} from 'helper/requestHelper';
import { getEnvironment } from 'helper/utils';

export const getStatistics =
  (id, start_date, end_date, type = 'users') =>
  async dispatch => {
    const actionKey =
      type === 'users'
        ? actionConstants.GET_USER_STATISTICS
        : actionConstants.GET_CUSTOMER_STATISTICS;
    dispatch({ type: startReqActionType(actionKey) });
    try {
      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      };

      const data = await fetch(
        `${
          getEnvironment().API_URL
        }statistics/${type}/${id}/${start_date}/${end_date}`,
        requestOptions
      );
      const retData = await processResponse(data);
      dispatch({
        type: successReqActionType(actionKey),
        payload: retData
      });
    } catch (error) {
      dispatch({ type: failReqActionType(actionKey) });
    }
  };
