import { actionConstants } from '../constants';
import { startReqActionType, successReqActionType } from 'helper/requestHelper';

const initialState = {
  queueSizes: {},
  isLoadingQueueSizes: false,
  error: null
};

const queueReducer = (state = initialState, action) => {
  switch (action.type) {
    case startReqActionType(actionConstants.GET_QUEUE_SIZES):
      return {
        ...state,
        isLoadingQueueSizes: true
      };
    case successReqActionType(actionConstants.GET_QUEUE_SIZES):
      return {
        ...state,
        isLoadingQueueSizes: false,
        queueSizes: action.payload
      };
    default:
      return state;
  }
};

export default queueReducer;
