import { useState, useEffect } from 'react';
import './ToolDetailPage.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  FormLabel,
  FormGroup,
  Button,
  Dropdown
} from 'react-bootstrap';
import { addTool, deleteTool, getTools, updateTool } from 'redux-layer/actions';

const ToolDetailPage = props => {
  const { tool_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { allTools } = useSelector(state => ({
    allTools: state.tools.allTools
  }));

  const [serverAddress, setServerAddress] = useState('');
  const [serverType, setServerType] = useState('');
  const [queueSize, setQueueSize] = useState(0);

  const currentTool = allTools[tool_id];

  useEffect(() => {
    if (tool_id !== 'new') {
      if (!currentTool) {
        dispatch(getTools());
      }
    }
  }, []);

  useEffect(() => {
    if (currentTool) {
      setServerAddress(currentTool.server_address);
      setServerType(currentTool.server_type);
      setQueueSize(currentTool.weighted_queue_size);
    }
  }, [currentTool]);

  const handleSubmit = () => {
    const payload = {
      server_address: serverAddress,
      server_type: serverType,
      weighted_queue_size: queueSize
    };
    if (currentTool) {
      dispatch(updateTool(tool_id, payload));
      navigate(`/tools`);
    } else {
      dispatch(
        addTool(payload, newID => {
          navigate(`/tools/${newID}`);
        })
      );
    }
  };

  const onDeleteTool = () => {
    if (window.confirm('Do you really want to delete this tool?')) {
      dispatch(deleteTool(tool_id));
    }
    navigate('/tools');
  };

  return (
    <div className='user-detail-page'>
      <div className='page-header'>
        <h1>
          {tool_id === 'new'
            ? 'New Tool'
            : currentTool
            ? currentTool.server_address
            : ''}
        </h1>
        <div className='actions'>
          <Dropdown
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <Dropdown.Toggle variant='primary' id='dropdown-play' size='md'>
              Actions
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey='delete' onClick={onDeleteTool}>
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-3'>
            <FormGroup>
              <FormLabel required>Server Address</FormLabel>
              <FormControl
                type='text'
                value={serverAddress}
                required
                placeholder='https://server_address:port/'
                onChange={e => setServerAddress(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className='col-md-3'>
            <FormGroup>
              <FormLabel required>Serever Type</FormLabel>
              <FormControl
                type='text'
                value={serverType}
                required
                placeholder='Tool Name'
                onChange={e => setServerType(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className='col-md-3'>
            <FormGroup>
              <FormLabel required>Weighted Queue Size</FormLabel>
              <FormControl
                type='text'
                value={queueSize}
                required
                placeholder='Tool Name'
                onChange={e => setQueueSize(Number(e.target.value))}
              />
            </FormGroup>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-6'>
            <Button onClick={handleSubmit} variant='primary'>
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ToolDetailPage };
