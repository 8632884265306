import {
  convertUTCtoLocal,
  getAttributeFromUser,
  shortenString
} from 'helper/utils';
import { NavLink } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const UserItem = props => {
  const { user, onSelectUser, searchOnly, selectedUser } = props;

  // const { Developer, Staff } = useSelector(state => ({
  //   Developer: state.users.Developer,
  //   Staff: state.users.Staff
  // }));

  if (!user) {
    return <></>;
  }

  // const developerList = Object.values(Developer);
  // const staffList = Object.values(Staff);

  // const developerUsernames = developerList.map(s => s.Username);
  // const staffUsernames = staffList.map(s => s.Username);

  const developerUsernames = [];
  const staffUsernames = [];

  return (
    <tr
      key={user.Username}
      className={`user-list-item ${
        developerUsernames.includes(user.Username) ? 'developer-user' : ''
      } ${staffUsernames.includes(user.Username) ? 'staff-user' : ''} ${
        searchOnly && selectedUser && selectedUser.Username === user.Username
          ? 'selected'
          : ''
      }`}
      onClick={() => {
        onSelectUser && onSelectUser(user);
      }}
    >
      <td className='username'>
        <NavLink to={'/users/' + user.Username} className='link-info'>
          {shortenString(user.Username)}
        </NavLink>
      </td>
      <td className='handle'>
        <NavLink to={'/users/' + user.Username} className='link-info'>
          {shortenString(getAttributeFromUser(user, 'preferred_username'))}
        </NavLink>
      </td>
      <td className='name'>
        {`${getAttributeFromUser(user, 'given_name')} ${getAttributeFromUser(
          user,
          'family_name'
        )}`}
      </td>
      <td className='email'>
        {shortenString(getAttributeFromUser(user, 'email'))}
      </td>
      <td className='email-verified'>
        {getAttributeFromUser(user, 'email_verified')}
      </td>
      <td className='credit'>{getAttributeFromUser(user, 'custom:credit')}</td>
      <td className='created-at'>
        {convertUTCtoLocal(user.UserCreateDate).format('ll')}
      </td>
      {/* <td className='group'>
        <div className='width-auto'>
          <Badge className='developer-badge' bg='primary'>
            Developer
          </Badge>
          <Badge className='staff-badge' bg='info'>
            Staff
          </Badge>
        </div>
      </td> */}
      {!searchOnly && (
        <td className='statistics'>
          <NavLink
            to={'/statistics/users/' + user.Username}
            className='btn btn-info text-white'
          >
            Statistics
          </NavLink>
        </td>
      )}
    </tr>
  );
};

export { UserItem };
