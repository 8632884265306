import { actionConstants } from '../constants';
import { startReqActionType, successReqActionType } from 'helper/requestHelper';

const initialState = {
  balances: {},
  isLoadingBalances: false,
  error: null
};

const serviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case startReqActionType(actionConstants.GET_SERVICE_BALANCES):
      return {
        ...state,
        isLoadingBalances: true
      };
    case successReqActionType(actionConstants.GET_SERVICE_BALANCES):
      return {
        ...state,
        isLoadingBalances: false,
        balances: action.payload
      };
    default:
      return state;
  }
};

export default serviceReducer;
