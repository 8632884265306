/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  FormGroup,
  FormLabel,
  FormControl,
  Button,
  Table
} from 'react-bootstrap';
import './MediaList.scss';
import { useDispatch, useSelector } from 'react-redux';
import { paginatedListMedias } from 'redux-layer/actions';
import Select from 'react-select';
import {
  PROCESSING_STATUS_OPTIONS,
  PUBLIC_STATUS_OPTIONS,
  RATING_OPTIONS
} from 'helper/constants';
import { MediaListItem } from 'components/MediaListItem';
import { VimmersePaginationBar } from 'components/VimmersePaginationBar';
import { CustomerSelect } from 'components/CustomerSelect';
// import { CSVLink } from 'react-csv';
// import moment from 'moment';

const MediaList = props => {
  const queryParam = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { paginatedMedias, lastEvaluatedKeys, totalCount } = useSelector(
    state => ({
      paginatedMedias: state.media.paginatedMedias,
      lastEvaluatedKeys: state.media.lastEvaluatedKeys,
      totalCount: state.media.totalCount
    })
  );

  const [statusFilter, setStatusFilter] = useState([]);
  const [ratingFilter, setRatingFilter] = useState([]);
  const [filterKey, setFilterKey] = useState('');
  const [createdByFilter, setCreatedByFilter] = useState(
    queryParam.get('username') || ''
  );
  const [publicStatusFilter, setPublicStatusFilter] = useState([]);
  const [perPage, setPerPage] = useState(40);
  const [page, setPage] = useState(1);
  const [customer, setCustomer] = useState(null);

  const searchParams = useSearchParams();

  useEffect(() => {
    setFilterKey(searchParams[0].get('keyword', ''));
    setCreatedByFilter(searchParams[0].get('created_by', ''));

    const visibilityValue = searchParams[0].get('visibility') || '';
    const visibilityValues = visibilityValue.split(',');
    const selectedPSO = PUBLIC_STATUS_OPTIONS.filter(pso =>
      visibilityValues.includes(pso.value.toString())
    );
    setPublicStatusFilter(selectedPSO);

    const statusValue = searchParams[0].get('status') || '';
    const statusValues = statusValue.split(',');
    const selectedStatus = PROCESSING_STATUS_OPTIONS.filter(pso =>
      statusValues.includes(pso.value)
    );
    setStatusFilter(selectedStatus);

    const ratingValue = searchParams[0].get('rating') || '';
    const rataingValues = ratingValue.split(',');
    const selectedRatings = RATING_OPTIONS.filter(ro =>
      rataingValues.includes(ro.value)
    );
    setRatingFilter(selectedRatings);

    const queryString = searchParams[0].toString();
    onStartSearch(queryString, true);
  }, []);

  const onStartSearch = (queryString, reset = false) => {
    dispatch(paginatedListMedias(queryString, reset));
  };

  const onSearch = (reset = false) => {
    if (reset) {
      setPage(1);
    }
    const statusFilterString = publicStatusFilter
      .map(obj => obj.value)
      .join(',');

    const params = new URLSearchParams({
      keyword: filterKey || '',
      created_by: createdByFilter || '',
      status: statusFilter.map(obj => obj.value).join(','),
      rating: ratingFilter.map(obj => obj.value).join(','),
      visibility: statusFilterString,
      last_evaluated_key: reset ? '' : JSON.stringify(lastEvaluatedKeys)
    });
    const queryString = params.toString();
    onStartSearch(queryString, reset);
    navigate(`/media?${queryString}`);
  };

  // const usersList = Object.values(allUsers || {});

  // const userNames = usersList.map(user => {
  //   const attributes = user.Attributes || user.UserAttributes;
  //   const preferred_username = getUserAttribute(
  //     attributes,
  //     'preferred_username'
  //   );
  //   const email = getUserAttribute(attributes, 'email');
  //   const given_name = getUserAttribute(attributes, 'given_name');
  //   const family_name = getUserAttribute(attributes, 'family_name');
  //   return {
  //     username: user.Username,
  //     preferred_username,
  //     email,
  //     given_name,
  //     family_name
  //   };
  // });

  // const csvData = mediaList.map(ml => {
  //   return {
  //     ...ml,
  //     handle: userNames.find(un => un.username === ml.primary_user_id)
  //       ?.preferred_username,
  //     status: getStatusText(ml.processing_status),
  //     visibility: PUBLIC_STATUS_OPTIONS.find(
  //       pso => pso.value === ml.visibility_status
  //     )?.label,
  //     url:
  //       (distributionServerURL.includes('dev-')
  //         ? 'https://www.vimmerse.co/content/'
  //         : 'https://www.vimmerse.net/content/') + ml.id,
  //     created_at_string: convertUTCtoLocal(ml.updated_at).format('llll')
  //   };
  // });

  // if (csvData && csvData[0]) {
  //   csvData[0]['current_month_count'] = currentMonthContent.length;
  //   csvData[0]['last_month_count'] = lastMonthContent.length;
  // }

  // const csvHeaders = [
  //   { label: 'Title', key: 'title' },
  //   { label: 'ID', key: 'id' },
  //   { label: 'Created By', key: 'handle' },
  //   { label: 'Status', key: 'status' },
  //   { label: 'Visibility', key: 'visibility' },
  //   { label: 'Created At', key: 'created_at_string' },
  //   { label: 'URL', key: 'url' },
  //   { label: '', key: '' },
  //   { label: 'Content Created This Month', key: 'current_month_count' },
  //   { label: 'Content Created Last Month', key: 'last_month_count' }
  // ];

  // const csvReport = {
  //   data: csvData,
  //   headers: csvHeaders,
  //   filename: 'Vimmerse Medias.csv'
  // };

  const onChangeRowsPerPage = newPerPage => {
    setPerPage(newPerPage);
    setPage(1);
  };

  const onChangePage = page => {
    const required_count = page * perPage;
    if (required_count > paginatedMedias.length) {
      if (lastEvaluatedKeys) {
        onSearch();
      } else {
        return;
      }
    }

    setPage(page);
  };

  let totalPages = 0;

  if (!lastEvaluatedKeys) {
    if (paginatedMedias.length) {
      totalPages = Math.ceil(totalCount / perPage);
    }
  }

  const displayingMedias = paginatedMedias.slice(
    (page - 1) * perPage,
    page * perPage
  );

  return (
    <div className='sessions-list-page'>
      <div className='page-header'>
        <h1>Media List</h1>
        <div>
          {/* <CSVLink {...csvReport} className='btn btn-secondary'>
            <strong>Download by CSV</strong>
          </CSVLink> */}
        </div>
      </div>
      <div className='contents'>
        <div className='row align-items-end pb-2'>
          <div className='col-md-2'>
            <FormGroup>
              <FormLabel>Customer</FormLabel>
              <CustomerSelect
                onChangeCustomer={setCustomer}
                customer={customer}
              />
            </FormGroup>
          </div>
          <div className='col-md-2'>
            <FormGroup>
              <FormLabel>Keyword</FormLabel>
              <FormControl
                type='text'
                value={filterKey}
                placeholder='Please input keyword to search'
                onChange={e => setFilterKey(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className='col-md-2'>
            <FormGroup>
              <FormLabel>Created By</FormLabel>
              <FormControl
                type='text'
                value={createdByFilter}
                placeholder='Please input creator to search'
                onChange={e => setCreatedByFilter(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className='col-md-2'>
            <FormGroup>
              <FormLabel>Status</FormLabel>
              <Select
                isMulti
                options={PROCESSING_STATUS_OPTIONS}
                value={statusFilter}
                onChange={setStatusFilter}
              />
            </FormGroup>
          </div>
          <div className='col-md-1'>
            <FormGroup>
              <FormLabel>Visibility</FormLabel>
              <Select
                isMulti
                options={PUBLIC_STATUS_OPTIONS}
                value={publicStatusFilter}
                onChange={setPublicStatusFilter}
              />
            </FormGroup>
          </div>
          <div className='col-md-1'>
            <FormGroup>
              <FormLabel>Rating</FormLabel>
              <Select
                isMulti
                options={RATING_OPTIONS}
                value={ratingFilter}
                onChange={setRatingFilter}
              />
            </FormGroup>
          </div>
          <div className='col-md-2'>
            <Button onClick={() => onSearch(true)}>Search</Button>
          </div>
        </div>
        <div className='mt-2'>
          <VimmersePaginationBar
            onChangePage={onChangePage}
            currentPage={page}
            isLastPage={!lastEvaluatedKeys && totalPages === page}
            onChangeRowsPerPage={onChangeRowsPerPage}
            rowsPerPage={perPage}
          />
          {/* {isLoadingMediaList && (<Alert variant='info'>Loading medias...</Alert>)} */}
          <Table striped responsive>
            <thead>
              <td>Thumbnail</td>
              <td>Title</td>
              <td>ID</td>
              <td>Batch ID</td>
              <td>Created By</td>
              <td>Updated At</td>
              <td>Status</td>
              <td>Visibility</td>
              <td>Motion Types</td>
              <td>Rating</td>
              <td></td>
            </thead>
            <tbody>
              {displayingMedias.map(pm => (
                <MediaListItem media={pm} key={pm.id} />
              ))}
            </tbody>
          </Table>
          <VimmersePaginationBar
            onChangePage={onChangePage}
            currentPage={page}
            isLastPage={!lastEvaluatedKeys && totalPages === page}
            onChangeRowsPerPage={onChangeRowsPerPage}
            rowsPerPage={perPage}
          />
        </div>

        {/* <DataTable
          columns={columns}
          data={paginatedMedias}
          defaultSortFieldId={defaultSortColumn}
          defaultSortAsc={false}
          pagination
          striped
          pointerOnHover
          paginationRowsPerPageOptions={[20, 40, 60]}
          paginationPerPage={40}
          paginationServer
          progressPending={isLoadingMediaList}

          paginationTotalRows={totalRows}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={onChangePage}

          

          // onRowClicked={(row, event) => {
          //   if (row.content_id) {
          //     navigate({
          //       pathname: '/session-detail/' + row.id,
          //       search: '?content_id=' + row.content_id,
          //     }, "_blank");
          //   } else {
          //     navigate('/session-detail/' + row.id, "_blank");
          //   }
          // }}
        /> */}
      </div>
    </div>
  );
};

export { MediaList };
