import { VIMMERSE_CUSTOMER_ID } from 'helper/constants';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllGroups, getCustomers, listPreset } from 'redux-layer/actions';

const GrabData = props => {
  const dispatch = useDispatch();
  const { isLoadingCustomers, allCustomers, isLoadingPreset, presets, groups } =
    useSelector(state => ({
      isLoadingCustomers: state.customers.isLoadingCustomers,
      allCustomers: state.customers.allCustomers,
      isLoadingPreset: state.media.isLoadingPreset,
      presets: state.media.presets,
      groups: state.users.groups
    }));

  useEffect(() => {
    if (!Object.keys(allCustomers).length && !isLoadingCustomers) {
      dispatch(getCustomers());
    }
    if (
      !isLoadingPreset &&
      (!presets.backgrounds || !presets.backgrounds.length)
    ) {
      dispatch(listPreset());
    }
    if (!groups.length) {
      dispatch(getAllGroups());
    }
  }, []);

  useEffect(() => {
    const customerString = localStorage.getItem('customer');
    if (!customerString) {
      if (Object.values(allCustomers).length) {
        const customersOptions = Object.values(allCustomers).map(co => ({
          value: co.id,
          label: co.name,
          customer_id: co.customer_id,
          customer_key: co.key || ''
        }));
        const defaultCustomer = customersOptions.find(
          co => co.customer_id === VIMMERSE_CUSTOMER_ID
        );
        if (defaultCustomer) {
          localStorage.setItem('customer', JSON.stringify(defaultCustomer));
        }
      }
    }
  }, [allCustomers]);

  return <></>;
};

export { GrabData };
