import { GeneralEditDialog } from 'components/GeneralEditDialog';
import { UserName } from 'components/UserName';
import { PUBLIC_STATUS_OPTIONS } from 'helper/constants';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { updateMedia } from 'redux-layer/actions';

const VimmerseMediaDetail = props => {
  const { media } = props;
  const [showEditDialog, setShowEditDialog] = useState(false);
  const dispatch = useDispatch();

  const envString = localStorage.getItem('myEnv');
  let envName = 'prod';
  if (envString) {
    const envValue = JSON.parse(envString);
    envName = envValue.value;
  }

  const websiteUrl =
    envName === 'prod' ? 'https://www.vimmerse.net' : 'https://www.vimmerse.co';

  if (!media) {
    return '';
  }

  const onUpdateVimmerseInfo = payload => {
    dispatch(updateMedia(media.id, payload));
    setShowEditDialog(false);
  };

  return (
    <div className='card vimmerse-media-detail'>
      <div className='card-body'>
        <div className='d-flex justify-content-between'>
          <h4>Vimmerse Data</h4>
          <Button
            variant='primary'
            onClick={() => {
              setShowEditDialog(true);
            }}
          >
            Edit
          </Button>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <div className='detail-item'>
              <h6>Title</h6>
              <p>{media.title}</p>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='detail-item'>
              <h6>Owner</h6>
              <p>
                <UserName primary_user_id={media.primary_user_id} />
              </p>
            </div>
          </div>
        </div>
        {media.description && (
          <div className='detail-item'>
            <h6>Description</h6>
            <p>{media.description}</p>
          </div>
        )}
        <div className='detail-item'>
          <h6>Website URL</h6>
          <a href={`${websiteUrl}/content/${media.id}`} target='_blank' rel="noreferrer">
            {`${websiteUrl}/content/${media.id}`}
          </a>
        </div>
        <div className='row'>
          <div className='col-md-3'>
            <div className='detail-item'>
              <h6>Visibility</h6>
              <p className='text-capitalize'>
                {
                  PUBLIC_STATUS_OPTIONS.find(
                    pso => pso.value === media.visibility_status
                  )?.label
                }
              </p>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='detail-item'>
              <h6>Show Priority</h6>
              <p className='text-capitalize'>{media?.sort_weight || 0}</p>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='detail-item'>
              <h6>Client Type</h6>
              <p className='text-capitalize'>{media.client_type}</p>
            </div>
          </div>
        </div>
        <div className='detail-item'>
          <h6>Error reason</h6>
          <p className='text-danger'>{media.error_message}</p>
        </div>
        <div className='detail-item'>
          <h6>Rating</h6>
          <p>{media.rating}</p>
        </div>
      </div>
      {showEditDialog && (
        <GeneralEditDialog
          media={media}
          onClose={() => {
            setShowEditDialog(false);
          }}
          onSubmit={onUpdateVimmerseInfo}
        />
      )}
    </div>
  );
};

export { VimmerseMediaDetail };
