import { OverlayTrigger } from 'react-bootstrap';
import { UserPopover } from './UserPopover';
import { useSelector } from 'react-redux';
import { getUserAttribute } from 'helper/utils';

const UserName = props => {
  const { allUsers } = useSelector(state => ({
    allUsers: state.users.allUsers
  }));

  const usersList = Object.values(allUsers || {});

  const userNames = usersList.map(user => {
    const attributes = user.Attributes || user.UserAttributes;
    const preferred_username = getUserAttribute(
      attributes,
      'preferred_username'
    );
    const email = getUserAttribute(attributes, 'email');
    const given_name = getUserAttribute(attributes, 'given_name');
    const family_name = getUserAttribute(attributes, 'family_name');
    return {
      username: user.Username,
      preferred_username,
      email,
      given_name,
      family_name
    };
  });

  const ownerInfo = userNames.find(o => o.username === props.primary_user_id);

  return (
    <OverlayTrigger
      trigger='click'
      rootClose
      placement='bottom'
      overlay={
        <div>
          <UserPopover username={props.primary_user_id} />
        </div>
      }
    >
      <div className='detail-item text-info'>
        <span>{ownerInfo?.preferred_username || props.primary_user_id}</span>
      </div>
    </OverlayTrigger>
  );
};

export { UserName };
