import {
  generateRandomString,
  getMotionTypesFromMedia
} from 'helper/utils';
import VideoJS from '../../components/Videos/VideoJS';
import { PIPELINE_PRESET_FAST } from 'helper/constants';

const SuccessContentViewer = props => {
  const { media } = props;
  const { threeD_version } = media;

  const randomString = generateRandomString();

  const threeDURL = `${media['base_url']}/3d/${
    threeD_version ? `${threeD_version}/` : ''
  }master.m3u8?`;

  const videoJsOptions1 = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: threeDURL,
        type: 'application/x-mpegURL',
        crossorigin: true
      }
    ]
  };

  let bulletVideoSrc = {
    src: `${media.base_url}/bullet/hls/master.m3u8?`,
    type: 'application/x-mpegURL',
    crossorigin: true
  };

  let thumbnailVideoSrc = {
    src: `${media.base_url}/bullet/thumbnail/hls/master.m3u8?`,
    type: 'application/x-mpegURL',
    crossorigin: true
  };

  if (
    media.submit_params &&
    media.submit_params['pipeline_preset'] &&
    media.submit_params['pipeline_preset'] === PIPELINE_PRESET_FAST
  ) {
    bulletVideoSrc = {
      src: `${media['base_url']}/bullet/${media.id}.mp4`,
      type: 'video/mp4'
    };

    thumbnailVideoSrc = {
      src: `${media['base_url']}/bullet/thumbnail/${media.id}_thumb.mp4`,
      type: 'video/mp4'
    };
  }

  const videoJsOptions2 = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    sources: bulletVideoSrc
  };

  const videoJsOptions3 = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    sources: thumbnailVideoSrc
  };

  const thumbnailImage = `${media['base_url']}/bullet/thumbnail/thumbnail.jpg`;

  const streamType = media.stream_type ? media.stream_type : 'png';

  const motionTypes = getMotionTypesFromMedia(media);

  const { bullet_version } = media;

  return (
    <div className='row content-section success-content-viewer'>
      <div className='col-md-3 sm-3 mb-3'>
        <h5>3D Content</h5>
        {streamType === 'png' ? (
          media['3d_available'] && (
            <img
              src={
                media['base_url'] + '/' + media.id + '_3d.png?v=' + randomString
              }
              alt='3d-image'
              className='w-100'
            />
          )
        ) : (
          <VideoJS options={videoJsOptions1} />
        )}
      </div>
      {motionTypes.map(mt => {
        const videoURL = `${media.base_url}/batch/${bullet_version}/${mt}/${media.id}.mp4`;
        return (
          <div className='col-md-2 sm-3 mb-3' key={mt}>
            <h5>{mt}</h5>
            <video controls className='w-100'>
              <source src={videoURL} type='video/mp4' />
            </video>
          </div>
        );
      })}
      <div className='col-md-2 sm-3 mb-3'>
        <h5>Bullet Video</h5>
        <VideoJS options={videoJsOptions2} />
      </div>
      <div className='col-md-2 sm-3 mb-3'>
        <h5>Thumbnail Image</h5>
        <img src={thumbnailImage} alt='thumbnail' className='w-100' />
      </div>
      <div className='col-md-2 sm-3 mb-3'>
        <h5>Thumbnail Video</h5>
        <VideoJS options={videoJsOptions3} />
      </div>
    </div>
  );
};

export { SuccessContentViewer };
