import { Popover } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getUserAttribute } from 'helper/utils';

const UserPopover = props => {
  const { allUsers } = useSelector(state => ({
    allUsers: state.users.allUsers
  }));

  const usersList = Object.values(allUsers || {});

  const userNames = usersList.map(user => {
    const attributes = user.Attributes || user.UserAttributes;
    const preferred_username = getUserAttribute(
      attributes,
      'preferred_username'
    );
    const email = getUserAttribute(attributes, 'email');
    const given_name = getUserAttribute(attributes, 'given_name');
    const family_name = getUserAttribute(attributes, 'family_name');
    return {
      username: user.Username,
      preferred_username,
      email,
      given_name,
      family_name
    };
  });

  const user = userNames.find(usr => usr.username === props.username);

  if (!user) {
    return '';
  }

  return (
    <Popover id='popover-trigger-click-root-close' className='user-popover'>
      <div>
        <span>
          <strong>Name:</strong> {`${user.given_name} ${user.family_name}`}
        </span>
      </div>
      <div>
        <span>
          <strong>Handle:</strong> @{user.preferred_username}
        </span>
      </div>
      <div>
        <span>
          <strong>username:</strong> {user.username}
        </span>
      </div>
      <div>
        <span>
          <strong>Email:</strong> {user.email}
        </span>
      </div>
    </Popover>
  );
};

export { UserPopover };
